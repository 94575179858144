import { dataTypeSymbol } from "../../../logged-in/shared/functions/Scorecard";
import { dateFormat } from "../../../logged-in/shared/utils/utils";
import { fullPerspectiveName } from "../../interfaces/IPerspectiveTabs";
import { IMeasure } from "../../models/Measure";
import { IObjective } from "../../models/Objective";
import { dataFormat } from "../Directives";
import { marginTopBottom, header, sectionHeader } from "./DocDefition";
import { brandLogo, footerStripes } from "./ImageLoader";

const tableWidths: Row = [
  100,
  "*",
  "*",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
];

const tableHeader: Row = [
  { text: "Perspective", style: "tableHeader" },
  { text: "Contributory Departmental Objective", style: "tableHeader" },
  { text: "Individual Contributory objective", style: "tableHeader" },
  { text: "Weight (%)", style: "tableHeader" },
  { text: "Measures/KPI", style: "tableHeader" },
  { text: "Baseline", style: "tableHeader" },
  { text: "Annual Target", style: "tableHeader" },
  { text: "Rating Scale 1-5", style: "tableHeader" },
  { text: "Key Initiatives", style: "tableHeader" },
  { text: "Target Date", style: "tableHeader" },
  { text: "Employee Midterm Rating", style: "tableHeader" },
  { text: "Midterm Supervisor Rating", style: "tableHeader" },
  { text: "Employee Assessment rating", style: "tableHeader" },
  { text: "Assessment Supervisor Rating", style: "tableHeader" },
  { text: "Source of Evidence", style: "tableHeader" },
  { text: "Comments", style: "tableHeader" },
];


type RowSpan = {
  text: string | any;
  rowSpan?: number;
  style?: string;
};

type Row = [
  string | number | RowSpan,
  string | RowSpan,
  string | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | RowSpan,
  string | RowSpan
];

export interface IEmployeeTableRowItem {
  perspective: string; // "Perspective",
  strategicObjective: string; // "Strategic Objectives",
  contributoryObjective: string; // "Contributory Departmental Objective",
  weight: number; // "Weight (%)",
  measure: string | number; // "Measures/KPI",
  baseline: string | number; // "Baseline",
  annualTarget: string | number; // "Annual Target",
  ratingScale: string; // "Annual Target",
  keyInitiatives: string | number; // "Key Initiatives",
  targetDate: string | number; // "Target Date",
  midtermAutoRating: number | null; // "midterm Auto Rating",
  midtermRating: number | null; // "midterm Supervisor Rating",
  autoRating: number | null; // "assessment Auto Rating",
  finalRating: number | null; // "assessment Supervisor Rating",
  sourceOfEvidence: string; // "Source of Evidence",
  comments: string; // "Comments",
  dataType?: string; // "Data type"
  dataSymbol?: string; // "Symbol"
}

const formatMeasureValue = (
  type: string,
  value: string | number | null,
  symbol: string
): string => {
  const { prefix, suffix } = dataTypeSymbol(type);

  if (type === "Ratios") {
    return value !== null && value !== undefined
      ? `${value} ${symbol}`
      : "unknown";
  }

  if (!value && value !== 0) return ""; // Check for null, undefined, or 0

  switch (type) {
    case "Date":
      return dateFormat(Number(value));
    case "Currency":
      return `${prefix}${Number(value).toFixed(2)}${suffix}`;
    default:
      return `${prefix}${value}${suffix}`;
  }
};



const ConvertToTableRowItem = (
  strategicObjectives: IObjective[],
  contributoryObjectives: IObjective[],
  measures: IMeasure[]
) => {

  console.log("Measure of individual ratings", measures);
  
  const tableRows: IEmployeeTableRowItem[] = measures.map((m) => {
    // get contributory objective
    const contributory = contributoryObjectives.find(
      (o) => o.id === m.objective
    );

    // get strategic objective
    const strategic = strategicObjectives.find((s) => {
      if (!contributory) return false;
      return s.id === contributory.parent;
    });

    const ratingScale = () => {
      const r1 = m.rating1
        ? `1 = ${dataFormat(m.dataType, m.rating1, m.dataSymbol)}`
        : "";
      const r2 = m.rating2
        ? `2 = ${dataFormat(m.dataType, m.rating2, m.dataSymbol)}`
        : "";
      const r3 = m.rating3
        ? `3 = ${dataFormat(m.dataType, m.rating3, m.dataSymbol)}`
        : "";
      const r4 = m.rating4
        ? `4 = ${dataFormat(m.dataType, m.rating4, m.dataSymbol)}`
        : "";
      const r5 = m.rating5
        ? `5 = ${dataFormat(m.dataType, m.rating5, m.dataSymbol)}`
        : "";

      const scale = `${r1}\n${r2}\n${r3}\n${r4}\n${r5}`;
      return scale;
    };
   const ratingScaleRatios = () => {
     const r1 = m.rating1Ratio !== "" ? `1 = ${m?.rating1Ratio}` : `1 = 0`;
     const r2 = m.rating2Ratio !== "" ? `2 = ${m?.rating2Ratio}` : `2 = 0`;
     const r3 = m.rating3Ratio !== "" ? `3 =  ${m?.rating3Ratio}` : `3 = 0`;
     const r4 = m.rating4Ratio !== "" ? `4 =  ${m?.rating4Ratio}` : `4 = 0`;
     const r5 = m.rating5Ratio !== "" ? `5 =  ${m?.rating5Ratio}` : `5 = 0`;

     const scale = `${r1}\n${r2}\n${r3}\n${r4}\n${r5}`;
     return scale;
   };
    const row: IEmployeeTableRowItem = {
      perspective: fullPerspectiveName(
        contributory ? contributory.perspective : ""
      ),
      strategicObjective: strategic ? strategic.description : "unknown",
      contributoryObjective: contributory ? contributory.description : "unkown",
      weight: contributory ? contributory.weight || 0 : 0,
      measure: m ? m.description : "unkown",
      baseline:
        m && m.dataType === "Ratios"
          ? (m.baselineRatio as string) // Ensure m.baselineRatio is considered a string here
          : formatMeasureValue(m.dataType, m.baseline, m.dataSymbol),
      annualTarget:
        m && m.dataType === "Ratios"
          ? (m.annualTargetRatio as string)
          : formatMeasureValue(m.dataType, m.annualTarget, m.dataSymbol),
      ratingScale:
        m && m.dataType === "Ratios" ? ratingScaleRatios() : ratingScale(),
      keyInitiatives: m ? m.activities : "unkown",
      targetDate: m ? m.targetDate : "unkown",
      midtermAutoRating: m ? m.midtermAutoRating : null,
      midtermRating: m ? m.midtermRating : null,
      autoRating: m ? m.autoRating : null,
      finalRating: m ? m.finalRating : null,
      sourceOfEvidence: m ? m.sourceOfEvidence : "unkown",
      comments: m ? m.comments : "unkown",
      dataType: m.dataType,
      dataSymbol: m.dataSymbol,
    };

    return row;
  });

  const sortByPerspective = (
    a: IEmployeeTableRowItem,
    b: IEmployeeTableRowItem
  ) => {
    const order = ["F", "C", "I", "L"];
    const aIndex = order.indexOf(a.perspective.charAt(0));
    const bIndex = order.indexOf(b.perspective.charAt(0));
    return (
      aIndex - bIndex ||
      a.strategicObjective.localeCompare(b.strategicObjective) ||
      a.contributoryObjective.localeCompare(b.contributoryObjective)
    );
  };
  return tableRows.sort(sortByPerspective);
};

const FormatTableSpan = (_rows: IEmployeeTableRowItem[]) => {
  let perspective = "";
  let objective = "";
  let cObjective = "";

  const rows: Row[] = _rows.map((row, _, data) => {
    let perspectiveRowSpan = undefined;
    let objectiveRowSpan = undefined;
    let cObjectiveRowSpan = undefined;

    if (perspective !== row.perspective) {
      perspective = row.perspective;
      perspectiveRowSpan = data.filter(
        (r) => r.perspective === perspective
      ).length;
    }

    if (objective !== row.strategicObjective) {
      objective = row.strategicObjective;
      objectiveRowSpan = data.filter(
        (r) => r.strategicObjective === objective
      ).length;
    }

    if (cObjective !== row.contributoryObjective) {
      cObjective = row.contributoryObjective;
      cObjectiveRowSpan = data.filter(
        (r) => r.contributoryObjective === cObjective
      ).length;
    }

    return [
      {
        rowSpan: perspectiveRowSpan || 0,
        text: row.perspective,
        fillColor: '#dedede'
      },
      {
        rowSpan: objectiveRowSpan || 0,
        text: row.strategicObjective,
      },
      {
        rowSpan: cObjectiveRowSpan || 0,
        text: row.contributoryObjective,
      },
      {
        rowSpan: cObjectiveRowSpan || 0,
        text: row.weight || "-",
      },
      row.measure || "-",
      row.baseline || "-",
      row.annualTarget || "-",
      row.ratingScale || "-",
      row.keyInitiatives || "-",
      row.targetDate || "-",
      { text: row.midtermAutoRating || 0, fillColor: '#fcecec' },
      { text: row.midtermRating || 0, fillColor: '#fcecec' },
      { text: row.autoRating || 0, fillColor: '#caf1de' },
      { text: row.finalRating || 0, fillColor: '#caf1de' },
      row.sourceOfEvidence || "-",
      row.comments || "-",
    ];
  });

  return rows;
};

export const IndividualPerformanceAgreementDocument = async (
  title: string,
  vision: string,
  mission: string,
  strategicObjectives: IObjective[],
  contributoryObjectives: IObjective[],
  measures: IMeasure[]
) => {
  const logo = await brandLogo();
  const footer = await footerStripes();

  const rows: IEmployeeTableRowItem[] = ConvertToTableRowItem(
    strategicObjectives,
    contributoryObjectives,
    measures
  );

  const mappedRows = FormatTableSpan(rows);

  const body = [tableHeader, ...mappedRows];

  return {
    pageSize: "A2", // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: "landscape",
    footer,
    content: [
      logo,
      marginTopBottom(),
      header(title),
      marginTopBottom(),
      sectionHeader("Mission:"),
      sectionHeader(mission),
      marginTopBottom(),
      sectionHeader("Vision:"),
      sectionHeader(vision),
      marginTopBottom(),

      {
        logo,
        table: {
          headerRows: 1,
          widths: tableWidths,
          body: body,
        },
      },
    ],
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: "black",
      },
    },
  };
};
