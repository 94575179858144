interface IProp {
  isActive: boolean;
  title: string;
  onClick: () => void;
}
const Tab = (props: IProp) => {
  const { isActive, title, onClick } = props;
  const activeClass = isActive ? "uk-active" : "";

  return (
    <li className={` ${activeClass}`} onClick={onClick}>
      <a href="#">{title}</a>
    </li>
  );
};

interface IProps {
  tab: "Employee" | "Exco" | "Department" | "Company";
  setTab: React.Dispatch<React.SetStateAction<"Employee" | "Exco" | "Department" | "Company">>;
}
export const ReviewTabs = (props: IProps) => {
  const { tab, setTab } = props;

  const setExco = () => setTab("Exco");
  const setEmployee = () => setTab("Employee");
  const setDepartment = () => setTab("Department");
  const setCompany = () => setTab("Company");

  return (
    <ul className="kit-tabs uk-margin" data-uk-tab>
      <Tab isActive={tab === "Exco"} title="EXCO Review" onClick={setExco} />
      <Tab isActive={tab === "Employee"} title="Employee Review" onClick={setEmployee} />
      <Tab isActive={tab === "Department"} title="Department Review" onClick={setDepartment} />
      <Tab isActive={tab === "Company"} title="Company Review" onClick={setCompany} />

    </ul>
  );
};
