import { useAppContext } from "../../shared/functions/Context";
import EmptyError from "../admin-settings/EmptyError";
import showModalFromId from "../../shared/functions/ModalShow";
import MODAL_NAMES from "../dialogs/ModalName";
import { IScorecardMetadata } from "../../shared/models/ScorecardMetadata";
import { observer } from "mobx-react-lite";
import ErrorBoundary from "../../shared/components/error-boundary/ErrorBoundary";

interface IReviewCompanyProps {
  scorecards: IScorecardMetadata[];
}
const ReviewCompany = observer((props: IReviewCompanyProps) => {
  const { scorecards } = props;
  const { store } = useAppContext();

  const statusClassName = (status: string) => `status status__${status}`;

  const onView = (item: IScorecardMetadata) => {
    showModalFromId(MODAL_NAMES.PERFORMANCE_REVIEW.COMPANY_REVIEW_MODAL);
    store.companyScorecardMetadata.select(item);
  };

  return (
    <ErrorBoundary>
      <div className="review-staff">
        <div className="review-staff--toolbar uk-margin">
          <h6 className="title">FY Reviews</h6>
        </div>
        <table className="people-table uk-table uk-table-small uk-table-middle uk-table-hover uk-table-divider">
          <thead className="header">
            <tr>
              <th>#</th>
              <th>FY</th>
              <th>Scorecard</th>
              <th>Q1 Status</th>
              <th>Midterm</th>
              <th>Q3 Status</th>
              <th>Assessment</th>
            </tr>
          </thead>
          <tbody>
            {scorecards.map((scorecard, index) => (
              <tr
                className="row"
                key={scorecard.uid}
                onClick={() => onView(scorecard)}
              >
                <td>{index + 1}</td>
                <td>{scorecard.displayName}</td>
                <td>
                  <div
                    className={statusClassName(scorecard.agreementDraft.status)}
                  >
                    {scorecard.agreementDraft.status}
                  </div>
                </td>
                <td>
                  <div
                    className={statusClassName(scorecard.quarter1Review.status)}
                  >
                    {scorecard.quarter1Review.status}
                  </div>
                </td>
                <td>
                  <div
                    className={statusClassName(scorecard.quarter2Review.status)}
                  >
                    {scorecard.quarter2Review.status}
                  </div>
                </td>
                <td>
                  <div
                    className={statusClassName(scorecard.quarter3Review.status)}
                  >
                    {scorecard.quarter3Review.status}
                  </div>
                </td>
                <td>
                  <div
                    className={statusClassName(scorecard.quarter4Review.status)}
                  >
                    {scorecard.quarter4Review.status}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!store.department.all.length && <EmptyError errorMessage="No scorecard found" />}
      </div>
    </ErrorBoundary>
  );
});

export default ReviewCompany;
