import { observer } from "mobx-react-lite";
import React, { Fragment, useMemo, useRef, useState, useEffect } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import Rating, { BarRating } from "../../shared/components/rating/Rating";
import { totalQ4DepartmentObjectiveRating } from "../../shared/functions/Scorecard";
import MeasureDepartment, { IMeasureDepartment } from "../../../shared/models/MeasureDepartment";
import ObjectiveItem from "./ObjectiveItem";
import ErrorBoundary from "../../../shared/components/error-boundary/ErrorBoundary";
import "./DeparmentDashboard.scss";
import ObjectiveDepartment, { IObjectiveDepartment, } from "../../../shared/models/ObjectiveDepartment";
import SingleSelect from "../../../shared/components/single-select/SingleSelect";
import Modal from "../../../shared/components/Modal";
import MODAL_NAMES from "../../dialogs/ModalName";
import StrategicMapObjectiveDepartmentModal from "../../dialogs/strategic-map-objective-department/StrategicMapObjectiveDepartmentModal";
import showModalFromId from "../../../shared/functions/ModalShow";

interface INoDataProps {
  message: string;
  children?: React.ReactNode;
}
const NoData = (props: INoDataProps) => {
  return (
    <div className="uk-margin-top uk-text-center">
      <div className="uk-card uk-card-body">
        <p className="uk-text-center">
          {props.message}
          {props.children && <br />}
          {props.children && <br />}
          {props.children}
        </p>
      </div>
    </div>
  );
};

interface IDepartmentProps {
  departmentOptions: {
    label: string;
    value: string;
  }[];
  departmentOption: string;
  setDepartmentOption: React.Dispatch<React.SetStateAction<string>>;
}
const DepartmentFilter = observer((props: IDepartmentProps) => {
  const { departmentOptions, departmentOption, setDepartmentOption, } = props;

  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (!firstRenderRef.current || departmentOptions.length === 0) return;
    setDepartmentOption(departmentOptions[0].value);
    firstRenderRef.current = false;
  }, [departmentOptions, setDepartmentOption]);


  return (
    <div className="dashboard--company--tab uk-margin-bottom">
      <div>
        <h5 className="objective-name uk-margin-remove-bottom uk-margin-right">
          Department
        </h5>
        <SingleSelect
          name="search-department"
          options={departmentOptions}
          width="300px"
          value={departmentOption}
          onChange={(opt) => setDepartmentOption(opt)}
          placeholder="Search a department by title..."
        />
      </div>
    </div>
  );
});

interface IMetricAnalyticProps {
  measures: MeasureDepartment[];
  objectives: ObjectiveDepartment[]
}

const MetricsAnalytics = observer((props: IMetricAnalyticProps) => {

  const { measures, objectives } = props;

  const redMeasures = measures.filter((measure) => measure.asJson.q4Rating || measure.asJson.q4AutoRating <= 2);

  const get_measures = (objective: ObjectiveDepartment): IMeasureDepartment[] => {
    return measures.filter((measure) => measure.asJson.objective === objective.asJson.id).map((measure) => measure.asJson);
  };

  const _total_scorecard_q1_rate = useMemo(() => {
    let _sum: number = 0;
    let final_rating: number[] = [];

    for (const objective of objectives) {
      const weight = objective.asJson.weight ? objective.asJson.weight / 100 : 0;

      const _measures = get_measures(objective).map((measure) => ({
        rating: measure.q1Rating || measure.q1AutoRating,
      }));
      let _avg_rates = _measures.reduce((acc, curr) => {
        return acc + curr.rating;
      }, 0) / _measures.length;
      //check for possible null value
      if (isNaN(_avg_rates)) return 0;
      _avg_rates = (weight * Math.round(_avg_rates * 10)) / 10;

      final_rating.push(_avg_rates);
      _sum = final_rating.reduce((acc, value) => {
        return acc + value;
      }, 0);
    }
    return _sum;
  }, [objectives, measures]);

  const _total_scorecard_midterm_rate = useMemo(() => {
    let _sum: number = 0;
    let final_rating: number[] = [];

    for (const objective of objectives) {
      const weight = objective.asJson.weight ? objective.asJson.weight / 100 : 0;

      const _measures = get_measures(objective).map((measure) => ({
        rating: measure.q2Rating || measure.q2AutoRating,
      }));
      let _avg_rates = _measures.reduce((acc, curr) => {
        return acc + curr.rating;
      }, 0) / _measures.length;
      //check for possible null value
      if (isNaN(_avg_rates)) return 0;
      _avg_rates = (weight * Math.round(_avg_rates * 10)) / 10;

      final_rating.push(_avg_rates);
      _sum = final_rating.reduce((acc, value) => {
        return acc + value;
      }, 0);
    }
    return _sum;
  }, [objectives, measures]);

  const _total_scorecard_q3_rate = useMemo(() => {
    let _sum: number = 0;
    let _rating: number[] = [];

    for (const objective of objectives) {
      const weight = objective.asJson.weight ? objective.asJson.weight / 100 : 0;

      const _measures = get_measures(objective).map((measure) => ({
        rating: measure.q3Rating || measure.q3AutoRating,
      }));
      let _avg_rates = _measures.reduce((acc, curr) => {
        return acc + curr.rating;
      }, 0) / _measures.length;
      //check for possible null value
      if (isNaN(_avg_rates)) return 0;
      _avg_rates = (weight * Math.round(_avg_rates * 10)) / 10;

      _rating.push(_avg_rates);
      _sum = _rating.reduce((acc, value) => {
        return acc + value;
      }, 0);
    }
    return _sum;
  }, [objectives, measures]);

  const _total_scorecard_final_rate = useMemo(() => {
    let _sum: number = 0;
    let _rating: number[] = [];

    for (const objective of objectives) {
      const weight = objective.asJson.weight ? objective.asJson.weight / 100 : 0;

      const _measures = get_measures(objective).map((measure) => ({
        rating: measure.q4Rating || measure.q4AutoRating,
      }));
      let _avg_rates = _measures.reduce((acc, curr) => {
        return acc + curr.rating;
      }, 0) / _measures.length;
      //check for possible null value
      if (isNaN(_avg_rates)) return 0;
      _avg_rates = (weight * Math.round(_avg_rates * 10)) / 10;
      _rating.push(_avg_rates);
      _sum = _rating.reduce((acc, value) => {
        return acc + value;
      }, 0);
    }
    return _sum;
  }, [objectives, measures]);

  const q1Css = _total_scorecard_q1_rate <= 2 ? "warning" : "primary";
  const midtermCss = _total_scorecard_midterm_rate <= 2 ? "warning" : "success";
  const q3Css = _total_scorecard_q3_rate <= 2 ? "warning" : "primary";
  const finalCss = _total_scorecard_final_rate <= 2 ? "warning" : "success";

  return (
    <div className="metrics-analytics">
      <div
        className="uk-grid-small uk-child-width-1-3@s uk-child-width-1-3@m uk-margin uk-grid-match" data-uk-grid style={{ marginBottom: "30px" }} >
        <div>
          <div className="info-card info-card--primary  uk-card uk-card-default uk-card-small">
            <div className="icon" data-tooltip="View all measures">
              <span>✓</span>
            </div>
            <div className="info-body uk-card-body">
              <p className="value">{measures.length}</p>
              <p className="label">All KPIs</p>
            </div>
          </div>
        </div>
        <div>
          <div className="info-card info-card--danger  uk-card uk-card-default uk-card-small">
            <div className="icon" data-tooltip="A red measures dashboard focuses on poorly performing metrics">
              <span>❗</span>
            </div>
            <div className="info-body uk-card-body">
              <p className="value">{redMeasures.length}</p>
              <p className="label">Red KPIs</p>
            </div>
          </div>
        </div>
        <div>
          <div className="info-card info-card--success  uk-card uk-card-default uk-card-small">
            <div className="icon" data-tooltip="View all objectives in corecard">
              <span>✓</span>
            </div>
            <div className="info-body uk-card-body">
              <p className="value">{objectives.length}</p>
              <p className="label">Objectives</p>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1">
        <div className="objectives-card uk-card uk-card-default uk-card-body uk-card-small">
          <h5 className="title uk-margin">Quaterly Scores ✓</h5>
          <div className="uk-grid-small uk-child-width-1-4@s uk-child-width-1-4@m uk-margin" data-uk-grid style={{ marginBottom: "30px" }}>
            <div>
              <div
                className={`info-card info-card--${q1Css}  uk-card uk-card-default uk-card-small`}>
                <div className="icon" data-tooltip="Average Q1 score">
                  {q1Css === "primary" ? <span>✓</span> : <span>❗</span>}
                </div>
                <div className="info-body uk-card-body">
                  <p className="value">{_total_scorecard_q1_rate.toFixed(2)}</p>
                  <p className="label">Q1 Rating</p>
                </div>
              </div>
            </div>
            <div>
              <div className={`info-card info-card--${midtermCss}  uk-card uk-card-default uk-card-small`}>
                <div className="icon" data-tooltip="Average Midterm score">
                  {midtermCss === "success" ? <span>✓</span> : <span>❗</span>}
                </div>
                <div className="info-body uk-card-body">
                  <p className="value">{_total_scorecard_midterm_rate.toFixed(2)}</p>
                  <p className="label">Midterm Rating</p>
                </div>
              </div>
            </div>
            <div>
              <div className={`info-card info-card--${q3Css}  uk-card uk-card-default uk-card-small`}>
                <div className="icon" data-tooltip="Average Q3 score">
                  {q3Css === "primary" ? <span>✓</span> : <span>❗</span>}
                </div>
                <div className="info-body uk-card-body">
                  <p className="value">{_total_scorecard_q3_rate.toFixed(2)}</p>
                  <p className="label">Q3 Rating</p>
                </div>
              </div>
            </div>
            <div>
              <div className={`info-card info-card--${finalCss}  uk-card uk-card-default uk-card-small`}>
                <div className="icon" data-tooltip="Average final score">
                  {finalCss === "success" ? <span>✓</span> : <span>❗</span>}
                </div>
                <div className="info-body uk-card-body">
                  <p className="value">{_total_scorecard_final_rate.toFixed(2)}</p>
                  <p className="label">Assessment</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});


interface IObjectiveAnalyticProps {
  measures: MeasureDepartment[];
  objectives: ObjectiveDepartment[]
}

const ObjectivesAnalytics = observer((props: IObjectiveAnalyticProps) => {
  const { measures, objectives } = props;
  const [viewType, setViewType] = useState<"table" | "grid">("grid");

  return (
    <div
      className="uk-grid-small uk-child-width-1-3@m uk-margin uk-grid-match"
      data-uk-grid style={{ marginBottom: "30px" }} >
      <div className="uk-width-1-1">
        <div className="objectives-card uk-card uk-card-default uk-card-body uk-card-small">
          <div className="objective-analytics-toolbar">
            <h5 className="title uk-margin">Objectives ✓</h5>
            <div className="controls" style={{ display: "flex" }} >
              <button className={"list-btn btn-icon uk-margin-small-right " + (viewType === "grid" ? "active" : "")}
                onClick={() => setViewType("grid")}
              >
                <span data-uk-icon="icon: grid"></span>
              </button>
              <button className={"list-btn btn-icon " + (viewType === "table" ? "active" : "")}
                onClick={() => setViewType("table")}
              >
                <span data-uk-icon="icon: table"></span>
              </button>
            </div>
          </div>
          {viewType === "table" && <ObjectivesTable objectives={objectives} />}
          {viewType === "grid" && <ObjectivesGrid measures={measures} objectives={objectives} />}
        </div>
      </div>
    </div>
  );
});


interface IObjectivesTableProps {
  objectives: ObjectiveDepartment[]
}

const ObjectivesTable = observer((props: IObjectivesTableProps) => {
  const { objectives } = props;

  return (
    <ul className="uk-list uk-list-striped uk-margin">
      {objectives.map((objective) => (
        <Fragment key={objective.asJson.id}>
          <ObjectiveItem objective={objective} />
        </Fragment>
      ))}

      {objectives.length === 0 && (
        <NoData message="No data to display. Please add a new objective.">
        </NoData>
      )}
    </ul>
  );
});


interface IObjectiveProps {
  measures: MeasureDepartment[];
  objectives: ObjectiveDepartment[]
}

const ObjectivesGrid = observer((props: IObjectiveProps) => {
  const { store } = useAppContext()
  const { measures, objectives } = props;

  // calculate rating
  const calculateRating = (objective: IObjectiveDepartment) => {
    const measures = getMeasures(objective);
    const rating = totalQ4DepartmentObjectiveRating(measures)// || totalQ2DepartmentObjectiveRating(measures);
    return rating || 1;
  };

  const getMeasures = (objective: IObjectiveDepartment): IMeasureDepartment[] => {
    return measures.filter((measure) => measure.asJson.objective === objective.id).map((measure) => measure.asJson);
  };

  const handleObjective = (objective: IObjectiveDepartment) => {
    store.departmentObjective.select(objective);
    showModalFromId(MODAL_NAMES.EXECUTION.DEPARTMENT_MAP_OVERVIEW_MODAL);
  };

  return (
    <div
      className="uk-grid-small uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-match"
      data-uk-grid
      style={{ marginBottom: "30px" }}
    >
      {objectives.map((objective) => (
        <div key={objective.asJson.id} onClick={() => handleObjective(objective.asJson)}>
          <div className="score uk-card uk-card-default uk-card-body uk-card-small">
            <h5 className="sub-heading">
              <span className="objective-name">Objective: </span>
              {objective.asJson.description}
            </h5>
            <ErrorBoundary>
              <div
                className="uk-grid-small uk-child-width-1-2 uk-grid-match uk-margin"
                data-uk-grid
                style={{ marginBottom: "30px" }}
              >
                <div>
                  <div className="rating-container">
                    <BarRating rating={calculateRating(objective.asJson)} />
                  </div>
                </div>
                <div>
                  <div className="rating-container">
                    <Rating
                      rate={objective.q4Rating.rate || objective.q2Rating.rate}
                      isUpdated={objective.q4Rating.isUpdated || objective.q2Rating.isUpdated}
                      simple={true}
                    />
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </div>
        </div>
      ))}
    </div>
  );
});



const MDDeparmentalDashboard = observer(() => {
  const { store } = useAppContext();

  const [departmentOption, setDepartmentOption] = useState("");

  const departmentOptions = store.department.all.map((department) => ({
    label: department.asJson.name,
    value: department.asJson.id,
  })).sort((a, b) => a.label.localeCompare(b.label));


  const measures = store.departmentMeasure.getByDepartment(departmentOption)
  const objectives = store.departmentObjective.getByDepartment(departmentOption)

  // console.log(measures);
  // console.log(objectives);

  // console.log("Departments ", departmentOptions);

  return (
    <>
      <div className="department-dashboard">
        <DepartmentFilter
          departmentOptions={departmentOptions}
          departmentOption={departmentOption}
          setDepartmentOption={setDepartmentOption}
        />
        <MetricsAnalytics measures={measures} objectives={objectives} />
        <ObjectivesAnalytics measures={measures} objectives={objectives} />
        {/* <MeasuresAnalytics measures={measures} /> */}
      </div>
      <Modal modalId={MODAL_NAMES.EXECUTION.DEPARTMENT_MAP_OVERVIEW_MODAL} cssClass="uk-modal-container" >
        <StrategicMapObjectiveDepartmentModal />
      </Modal>
    </>
  );
});

export default MDDeparmentalDashboard;
// StrategicMapObjectiveModal company



// interface IMeasureProps {
//   measures: MeasureDepartment[]
// }

// const MeasuresAnalytics = observer((props: IMeasureProps) => {

//   const { measures } = props

//   const redMeasures = measures.filter((measure) => measure.asJson.q4Rating! < 2);
//   const greenMeasures = measures.filter((measure) => measure.asJson.q4Rating! >= 3);
//   const amberMeasures = measures.filter((measure) => measure.asJson.q4Rating! < 3 && measure.asJson.q4Rating! >= 2);

//   return (
//     <div
//       className="uk-grid-small uk-child-width-1-3@m uk-margin uk-grid-match"
//       data-uk-grid
//       style={{ marginBottom: "30px" }}
//     >
//       <div>
//         <div className="red-measures-card uk-card uk-card-default uk-card-body uk-card-small">
//           <h5 className="title uk-margin">Green KPIs/Measures 🙂</h5>
//           <ul className="uk-list uk-list-striped uk-margin">
//             {greenMeasures.map((measure) => (
//               <ErrorBoundary key={measure.asJson.id}>
//                 <MeasureItem measure={measure.asJson} />
//               </ErrorBoundary>
//             ))}
//             {greenMeasures.length === 0 && (
//               <NoData message="No data to display. " />
//             )}
//           </ul>
//         </div>
//       </div>

//       <div>
//         <div className="red-measures-card uk-card uk-card-default uk-card-body uk-card-small">
//           <h5 className="title uk-margin">Amber KPIs/Measures 😐</h5>
//           <ul className="uk-list uk-list-striped uk-margin">
//             {amberMeasures.map((measure) => (
//               <ErrorBoundary key={measure.asJson.id}>
//                 <MeasureItem measure={measure.asJson} />
//               </ErrorBoundary>
//             ))}

//             {amberMeasures.length === 0 && (
//               <NoData message="No data to display. " />
//             )}
//           </ul>
//         </div>
//       </div>
//       <div>
//         <div className="red-measures-card uk-card uk-card-default uk-card-body uk-card-small">
//           <h5 className="title uk-margin">All Red KPIs/Measures 😔</h5>
//           <ul className="uk-list uk-list-striped uk-margin">
//             {redMeasures.map((measure) => (
//               <ErrorBoundary key={measure.asJson.id}>
//                 <MeasureItem measure={measure.asJson} />
//               </ErrorBoundary>
//             ))}
//             {redMeasures.length === 0 && (
//               <NoData message="No red measures 🙂." />
//             )}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// });