// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDahSY93BdDG2gz9wHyPmDk5bn_MpROCaQ",
  authDomain: "pms-system-63c4d.firebaseapp.com",
  projectId: "pms-system-63c4d",
  databaseURL: "https://pms-system-63c4d.firebaseio.com",
  storageBucket: "pms-system-63c4d.appspot.com",
  messagingSenderId: "660500598017",
  appId: "1:660500598017:web:281ddf101c196a7c201f00",
  measurementId: "G-E33X7DF3NM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const appAuthWorker = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});
export const auth = getAuth(app);
export const authWorker = getAuth(appAuthWorker);
export const storage = getStorage(app);
export const functions = getFunctions(app);
