import { observer } from "mobx-react-lite";
import { useAppContext } from "../../../shared/functions/Context";
import { useEffect, useState } from "react";
import { uploadFileToFirebaseStorage } from "./uploadFileFunction";
import "firebase/storage"; // Import Firebase Storage module
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import Loading, {
  LoadingEllipsis,
} from "../../../shared/components/loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";


const storage = getStorage();

interface Props {
  msg: "Successfully uploaded";
  children?: any;
  onClose?: () => void;
}

export const UploadDocs = observer(() => {
  const { store, api, ui } = useAppContext(); // Assuming you have storage from your context
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentUser = store.auth.me;
  const name = currentUser?.asJson.displayName || "";

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const uploadDocument = async () => {
    setLoading(true);
    if (selectedFile) {
      await uploadFileToFirebaseStorage(storage, selectedFile, name);
      console.log("File uploaded successfully");
      fetchFilesInFolder();
      sendMail();
      setSelectedFile(null);
    }
    setLoading(false);
  };

  const [filesInFolder, setFilesInFolder] = useState<
    { name: string; downloadUrl: string; lastModified: number }[]
  >([]);

  const fetchFilesInFolder = async () => {
    setLoading(true);
    try {
      const listRef = ref(storage, "Forms/");
      const res = await listAll(listRef);

      const fileItems = await Promise.all(
        res.items.map(async (itemRef) => {
          // Get download URL, last modified date, or any other metadata you need
          const downloadUrl = await getDownloadURL(itemRef);
          const metadata = await getMetadata(itemRef);

          // Convert the lastModified date string to a timestamp
          const lastModifiedTimestamp = Date.parse(metadata.updated || "0");

          return {
            name: itemRef.name,
            downloadUrl,
            lastModified: lastModifiedTimestamp,
          };
        })
      );

      // Sort files by latest last modified date
      const sortedFileItems = fileItems.sort(
        (a, b) => b.lastModified - a.lastModified
      );

      setFilesInFolder(sortedFileItems);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFilesInFolder();
  }, []);

  useEffect(() => {
    fetchFilesInFolder();
  }, []);

  const groupedFiles = filesInFolder.reduce((groups: any, file) => {
    const date = new Date(file.lastModified);
    const monthYear = `${date.getFullYear()}-${date.getMonth() + 1}`;
    const link = file.downloadUrl;

    if (!groups[monthYear]) {
      groups[monthYear] = [];
    }

    groups[monthYear].push(file);
    return groups;
  }, {});

  // console.log("🚀 ~ file: UploadDocuments.tsx:71 ~ UploadDocs ~ user:", currentUser?.asJson.displayName);
  const message = `A Performance Management System issue has been reported by ${currentUser?.asJson.displayName} from Namcor. Kindly address this matter.`;

  const sendMail = async () => {
    setLoading(true);
    await api.mail.sendMailUpload(
      [
        "ndapanda@lotsinsights.com",
        "ict@lotsinsights.com",
        "engdesign@lotsinsights.com",
      ],
      "no-reply@namcor.unicomms",
      ["", ""],
      "Ticket Issue Logged",
      message,
      `Ticket Number: 223232`
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingEllipsis />
      ) : (
        <div>
          <div className="uk-margin" data-uk-margin>
            <div uk-form-custom="target: true">
              <input
                type="file"
                aria-label="Custom controls"
                onChange={handleFileChange}
              />
              <input
                className="uk-input uk-form-width-medium"
                type="text"
                placeholder="Select file"
                aria-label="Custom controls"
                disabled
              />
            </div>
            <button
              className="uk-button uk-button-default"
              onClick={uploadDocument}
            >
              Upload
            </button>
          </div>

          {Object.keys(groupedFiles).map((groupKey) => (
            <div key={groupKey}>
              <h6>
                {new Date(groupKey).toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                })}
              </h6>
              <div
                className="uk-child-width-1-4@m uk-grid-small uk-grid-match"
                data-uk-grid
              >
                {groupedFiles[groupKey].map((file: any) => (
                  <div key={file.name} className="uk-card">
                    <a
                      href={file.downloadUrl}
                      download={true}
                      className="download-link"
                    >
                      <FontAwesomeIcon
                        icon={faFileWord}
                        className="icon uk-margin-small-right"
                        style={{ fontSize: "2rem", color: "grey" }}
                      />
                      <p style={{ fontSize: "12px" }}>
                        {file.name} -{" "}
                        {new Date(file.lastModified).toLocaleString()}
                      </p>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});
