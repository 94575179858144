import React, { useState } from "react";
import ErrorBoundary from "../../../shared/components/error-boundary/ErrorBoundary";
import { LoadingEllipsis } from "../../../shared/components/loading/Loading";
import useTitle from "../../../shared/hooks/useTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const UserManual = () => {
  const [loading, setLoading] = useState(false);
  const pdfUrl = process.env.PUBLIC_URL + "/manual.pdf";
  useTitle("User Manual");

  return (
    <ErrorBoundary>
      <div className="dashboard uk-section uk-section-small ">
        <div className="uk-container uk-container-xlarge announcements">
          <div style={{ width: "20%" }}>
            <a
              href={pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="center-content"
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                className="icon uk-margin-small-right"
                style={{ fontSize: "4rem", color: "grey" }}
              />
            </a>
            <p className="center-content">User Manual</p>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default UserManual;
