import { dateFormat } from "../../logged-in/shared/utils/utils";

export const currencySymbols = {
  NAD: "NAD",
  USD: "USD",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  AUD: "AUD",
  CAD: "CAD",
  CHF: "CHF",
  NZD: "NZD",
  YEN: "YEN",
} as const;

export type Currency = keyof typeof currencySymbols;

export const dataFormat = (
  dataType:
    | string
    | "Date"
    | "Percentage"
    | "Number"
    | "Currency"
    | "Time"
    | "Custom",
  value: number | null | string,
  dataSymbol: string,
  symbolPos?: "prefix" | "suffix"
) => {
  if ((!value || value === undefined) && value !== 0) return "-";

  switch (dataType) {
    case "Date":
      return dateFormat(value);
    case "Percentage":
      return `${value}%`;
    case "Number":
      return numberFormat(Number(value));
    case "Currency":
      const val = currencyFormat(value, dataSymbol as Currency);
      return `${val}`;
    case "Time":
      return `${value} ${dataSymbol}`;
    case "Custom":
      return `${value} (${dataSymbol})`;
    default:
      return `${value} ${dataSymbol}`;
  }
};

export const currencyFormat = (
  value: number | null | string,
  currency: Currency = "NAD"
) => {
  // if it is not a number, or undefined, return empty string
  if (value === null || value === undefined) return "-";
  const numValue = Number(value);
  if (isNaN(numValue)) return "-";

  try {
    // Use custom symbol formatting instead of Intl.NumberFormat for specific symbols
    const symbol = currencySymbols[currency] || currency;
    return ` ${numValue.toFixed(2)}${symbol}`;
  } catch (error) {
    // If there's an error, return the value with the currency symbol
    return `${currency} ${numValue.toFixed(2)}`;
  }
};

export const numberFormat = (value: number) => {
  if (value === 0) return "0";
  // if it is not a number, or undefined, return empty string
  if (!value || isNaN(value)) return "-";

  return new Intl.NumberFormat("en-US", {
    style: "decimal",
  }).format(value);
};
