import { observer } from "mobx-react-lite";
import ErrorBoundary from "../../../shared/components/error-boundary/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import useTitle from "../../../shared/hooks/useTitle";
import "../announcements/Announcement.scss";

export const Announcements = observer(() => {
  const pdfUrl = process.env.PUBLIC_URL + "/Namcor.pdf"; // Construct the PDF URL
  useTitle("Announcements");
  return (
    <ErrorBoundary>
      <div className="dashboard uk-section uk-section-small ">
        <div className="uk-container uk-container-xlarge announcements">
          <div style={{ width: "20%" }}>
            <a
              href={pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="center-content"
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                className="icon uk-margin-small-right"
                style={{ fontSize: "4rem", color: "grey" }}
              />
            </a>
            <p className="center-content">
              LOTS Standard Ticket Logging Procedure
            </p>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
});
