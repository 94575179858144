import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";

export const defaultMeasure: IMeasure = {
  id: "",
  uid: "",
  userName: "",
  objective: "",
  department: "",
  perspective: "",
  description: "",
  comments: "",
  activities: "",
  baseline: null,
  rating1: null,
  rating2: null,
  rating3: null,
  rating4: null,
  rating5: null,
  annualTarget: 0,
  midtermActual: null,
  annualActual: null,

  midtermAutoRating: 0,
  midtermRating: null,

  autoRating: 0,
  finalRating: null,

  weight: 0,
  dataType: "Currency",
  dataSymbol: "NAD",
  symbolPos: "prefix",
  sourceOfEvidence: "",
  targetDate: "",
};

export interface IMeasure {
  id: string;
  uid: string;
  userName: string;
  objective: string;
  department: string;
  perspective: string; // Finacial, Customer, Operational, Learning & Growth,
  description: string;
  comments: string;
  activities: string;
  baseline: number | null;
  baselineRatio?: string;
  rating1: number | null; // required field
  rating1Ratio?: string;
  rating2: number | null; // required field
  rating2Ratio?: string;
  rating3: number | null; // required field
  rating3Ratio?: string;
  rating4: number | null;
  rating4Ratio?: string;
  rating5: number | null;
  rating5Ratio?: string;
  annualTarget: number | null;
  annualTargetRatio?: string;
  midtermActual: number | null;
  annualActual: number | null;
  targetDate: number | string;

  midtermAutoRating: number; //midterm auto rating
  midtermRating: number | null; // supervisor midterm rating

  autoRating: number; //final auto rating
  finalRating: number | null; // supervisor final rating

  weight: number; // percentage
  dataType: string; // Number | Text | Date | Percentage | Currency | YesNo | Rating
  dataSymbol: string; // %, $, €, £
  symbolPos: "prefix" | "suffix" | "none"; // prefix / suffix
  sourceOfEvidence: string;
  isUpdated?: boolean;
}

export default class Measure {
  private measure: IMeasure;

  constructor(private store: AppStore, measure: IMeasure) {
    makeAutoObservable(this);
    this.measure = measure;
  }

  get asJson(): IMeasure {
    return toJS(this.measure);
  }
}
