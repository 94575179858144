import Toolbar from "../shared/components/toolbar/Toolbar";
import Modal from "../../shared/components/Modal";
import MODAL_NAMES from "../dialogs/ModalName";
import { observer } from "mobx-react-lite";
import { useAppContext } from "../../shared/functions/Context";
import StrategicMapObjectiveModal from "../dialogs/strategic-map-objective/StrategicMapObjectiveModal";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useTitle from "../../shared/hooks/useTitle";
import useBackButton from "../../shared/hooks/useBack";
import ErrorBoundary from "../../shared/components/error-boundary/ErrorBoundary";
import { LoadingEllipsis } from "../../shared/components/loading/Loading";
import { generateIndividualPerformanceAgreementPDF } from "../../shared/functions/scorecard-pdf/GeneratePerformaneAgreementPDF";
import { faFileExcel, faArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { exportEmployeeExcelScorecard } from "../shared/functions/Excel";
import { IUser } from "../../shared/models/User";
import { IScorecardBatch } from "../../shared/models/ScorecardBatch";
import useVM from "../../shared/hooks/useVM";
import { IScorecardArchive } from "../../shared/models/ScorecardArchive";
import { dataFormat } from "../../shared/functions/Directives";
import { fullPerspectiveName } from "../../shared/interfaces/IPerspectiveTabs";
import { IMeasure } from "../../shared/models/Measure";
import Objective, { IObjective } from "../../shared/models/Objective";
import EmptyError from "../admin-settings/EmptyError";
import Rating from "../shared/components/rating/Rating";
import {
  rateColor,
  totalFinalIndividualObjectiveRating,
} from "../shared/functions/Scorecard";
import "./PeopleView.scss";

const NoMeasures = () => {
  return (
    <div className="no-measures">
      <p className="uk-text-center">
        Empty (no measures) <span>😔</span>
      </p>
    </div>
  );
};

interface IMeasureItemProps {
  measure: IMeasure;
}
const MeasureTableItem = observer((props: IMeasureItemProps) => {
  const { measure } = props;

  const dataType = measure.dataType;
  const dataSymbol = measure.dataSymbol;

  const rateCss = rateColor(measure.autoRating, measure.isUpdated);

  const rateCssSupervisor = rateColor(
    measure.finalRating || 0,
    measure.isUpdated
  );

  return (
    <tr className="row">
      <td>
        <div className={`status ${rateCss}`}></div>
      </td>
      <td>{measure.description}</td>
      <td className="no-whitespace">
        {dataFormat(dataType, measure.baseline, dataSymbol)}
      </td>
      <td className="no-whitespace">
        {dataFormat(dataType, measure.annualTarget, dataSymbol)}
      </td>
      <td className={`actual-value ${rateCss}`}>
        {dataFormat(dataType, measure.annualActual, dataSymbol)}
      </td>
      <td className={`actual-value ${rateCss}`}>{measure.autoRating}</td>
      <td className={`actual-value ${rateCssSupervisor}`}>
        {measure.finalRating}
      </td>
    </tr>
  );
});

interface IMeasureProps {
  measures: IMeasure[];
}
const MeasureTable = observer((props: IMeasureProps) => {
  const { measures } = props;
  const [isEmpty, setisEmpty] = useState(false);

  useEffect(() => {
    setisEmpty(measures.length === 0 ? true : false);
  }, [measures]);

  return (
    <ErrorBoundary>
      <div className="measure-table">
        {!isEmpty && (
          <table className="measure-table uk-table uk-table-small uk-table-middle uk-table-hover uk-table-divider">
            <thead className="header">
              <tr>
                <th></th>
                <th className="uk-width-expand@s">Measure</th>
                <th>Baseline</th>
                <th>Target</th>
                <th>Progress</th>
                <th>Rating</th>
                <th>Rating (Sup)</th>
              </tr>
            </thead>
            <tbody>
              {measures.map((measure) => (
                <ErrorBoundary key={measure.id}>
                  <MeasureTableItem measure={measure} />
                </ErrorBoundary>
              ))}
            </tbody>
          </table>
        )}
        {isEmpty && <NoMeasures />}
      </div>
    </ErrorBoundary>
  );
});

interface ItemProps {
  objective: IObjective;
  isUpdated: boolean;
  rating: number;
  children?: React.ReactNode;
}
const ObjectiveTableItem = observer((props: ItemProps) => {
  const { rating, isUpdated, objective, children } = props;

  return (
    <div className="objective uk-card uk-card-default uk-card-small uk-card-body uk-margin">
      <div className="uk-flex uk-flex-middle">
        <div className="uk-margin-right">
          <Rating rate={rating} isUpdated={isUpdated} />
        </div>
        <h3 className="objective-name uk-width-1-1">
          {objective.description}{" "}
          <span className="objective-persepctive">
            {fullPerspectiveName(objective.perspective)}
          </span>
        </h3>
      </div>
      <div className="uk-margin">{children}</div>
    </div>
  );
});

interface IObjectiveTableProps {
  objectives: Objective[];
  getMeasures: (objective: IObjective) => IMeasure[];
}

const ObjectiveTable = observer((props: IObjectiveTableProps) => {
  const { objectives, getMeasures } = props;

  // calculate rating
  const calculateRating = (objective: IObjective) => {
    const measures = getMeasures(objective);
    const measuresUpdated = measures.filter((m) => m.isUpdated).length > 0;
    const rating = totalFinalIndividualObjectiveRating(measures);
    return {
      rate: rating || 1,
      isUpdated: measuresUpdated,
    };
  };

  // Group objectives by perspective
  const perspectives: { [key: string]: IObjective[] } = {};
  objectives.forEach((objective) => {
    const perspective = objective.asJson.perspective;

    if (!perspectives[perspective]) {
      perspectives[perspective] = [];
    }

    perspectives[perspective].push(objective.asJson);
  });

  return (
    <ErrorBoundary>
      <div className="objective-table uk-margin">
        {Object.entries(perspectives).map(([perspective, objectives]) => (
          <div key={perspective}>
            <h5 style={{ textTransform: "uppercase", fontSize: "14px" }}>
              {perspective}
            </h5>
            {objectives.map((objective) => (
              <ErrorBoundary key={objective.id}>
                <ObjectiveTableItem
                  objective={objective}
                  rating={calculateRating(objective).rate}
                  isUpdated={calculateRating(objective).isUpdated}
                >
                  <MeasureTable measures={getMeasures(objective)} />
                </ObjectiveTableItem>
              </ErrorBoundary>
            ))}
          </div>
        ))}

        {/* Empty */}
        {!objectives.length && <EmptyError errorMessage="No objective found" />}
      </div>
    </ErrorBoundary>
  );
});

interface IListProps {
  objectives: Objective[];
}
const StrategicList = observer((props: IListProps) => {
  const { store } = useAppContext();
  const { objectives } = props;

  const getMeasures = (objective: IObjective): IMeasure[] => {
    return store.measure.all
      .filter((measure) => measure.asJson.objective === objective.id)
      .map((measure) => measure.asJson);
  };

  return (
    <div className="strategic-list uk-margin">
      <div>
        <ObjectiveTable objectives={objectives} getMeasures={getMeasures} />
      </div>
    </div>
  );
});

const PeopleView = observer(() => {
  const { api, store, ui } = useAppContext();
  const { vision, mission } = useVM();
  const { uid } = useParams();

  const [loading, setLoading] = useState(false);
  const [archiving, setArchiving] = useState(false);

  const [_, setTitle] = useTitle("People"); // set page title

  const navigate = useNavigate();
  useBackButton("/c/scorecards/people/");

  const me = store.auth.meJson;
  const user = store.user.selected;
  const scorecard = store.scorecard.active;

  const objectives = store.objective.getByUid(uid!);
  const measures = store.measure.getByUid(uid!);
  const measureAudits = store.measureAudit.getByUid(uid!);

  const validMeasures = measures.filter(
    (measure) => measure.asJson.finalRating !== null
  );

  const getOverall = () => {
    if (validMeasures.length > 0) {
      const overall = validMeasures.reduce(
        (total, measure) => total + (measure.asJson.finalRating || 0),
        0
      );
      const averageRating = overall / measures.length;
      return averageRating.toFixed(2);
    } else {
    }
    return "";
  };

  const strategicObjectives =
    [
      ...store.departmentObjective.all.map((o) => o.asJson),
      ...store.companyObjective.all.map((o) => o.asJson),
    ] || [];
  const contributoryObjectives = objectives.map((o) => o.asJson) || [];
  const allMeasures = measures.map((o) => o.asJson) || [];

  const deleteObjectives = async () => {
    for (const objective of objectives) {
      await api.objective.delete(objective.asJson);
    }
  };

  const deleteMeasures = async () => {
    for (const measure of measures) {
      await api.measure.delete(measure.asJson);
    }
  };

  const deleteMeasuresAudits = async () => {
    for (const measureAudit of measureAudits) {
      await api.measure.delete(measureAudit.asJson);
    }
  };

  const onArchive = async (archive: IScorecardArchive) => {
    try {
      await api.scorecardaArchive.create(archive);
      await deleteObjectives();
      await deleteMeasures();
      await deleteMeasuresAudits();
      ui.snackbar.load({
        id: Date.now(),
        message: "Scorecard Archived.",
        type: "success",
      });
    } catch (error) {
      ui.snackbar.load({
        id: Date.now(),
        message: "Error! Failed to archive scorecard.",
        type: "danger",
      });
    }
  };

  const onArchiveScorecard = async () => {
    if (
      !window.confirm(
        "This action cannot be undone, the data will be moved to the archive folder."
      )
    )
      return;
    setArchiving(true);
    const _objectives = objectives.map((o) => o.asJson);
    const _measures = measures.map((m) => m.asJson);
    const _measureAudits = measureAudits.map((m) => m.asJson);

    const $archive: IScorecardArchive = {
      uid: user!.uid,
      displayName: user!.displayName,
      archiverUid: me!.uid,
      archiverDisplayName: me!.displayName,
      objectives: _objectives,
      measures: _measures,
      measureAudits: _measureAudits,
      isLocked: false,
    };
    await onArchive($archive);
    setArchiving(false);
  };

  const loadCompanyAndDepartmnBeforeExport = async (
    scorecard: IScorecardBatch,
    user: IUser
  ) => {
    try {
      // Only get when exporting and drafting.
      await api.companyMeasure.getAll(scorecard.id);
      await api.companyObjective.getAll(scorecard.id);
      // Get all on-load.
      await api.departmentMeasure.getAll(scorecard.id, user.department);
      await api.departmentObjective.getAll(scorecard.id, user.department);
    } catch (error) {
      ui.snackbar.load({
        id: Date.now(),
        message: "Error! Failed to export scorecard.",
        type: "danger",
      });
    }
  };

  // Export reports
  const handleExportPDF = async () => {
    if (!scorecard || !user) return;
    await loadCompanyAndDepartmnBeforeExport(scorecard, user);

    const title = `${user.displayName} ${scorecard.description} Scorecard`;
    const titleWithOverallRating = `${title} Overall Rating: ${getOverall()}`;

    try {
      generateIndividualPerformanceAgreementPDF(
        titleWithOverallRating,
        vision,
        mission,
        strategicObjectives,
        contributoryObjectives,
        allMeasures
      );
    } catch (error) {
      ui.snackbar.load({
        id: Date.now(),
        message: "Error! Failed to export scorecard.",
        type: "danger",
      });
    }
  };

  const handleExportExcel = async () => {
    if (!scorecard || !user) return;
    await loadCompanyAndDepartmnBeforeExport(scorecard, user);

    const title = `${user.displayName} ${scorecard.description} Scorecard`;

    try {
      await exportEmployeeExcelScorecard(
        title,
        strategicObjectives,
        contributoryObjectives,
        allMeasures
      );
    } catch (error) {
      ui.snackbar.load({
        id: Date.now(),
        message: "Error! Failed to export scorecard.",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    const setPageTitle = () => {
      if (!user || !uid) navigate("/c/scorecards/people/");
      else setTitle(`Scorecard for ${user.displayName}`);
    };
    setPageTitle();
  }, [navigate, setTitle, user, uid]);

  useEffect(() => {
    if (!uid || !user) return;
    const load = async () => {
      setLoading(true);
      await api.objective.getAll(uid);
      await api.measure.getAll(uid);
      setLoading(false);
    };
    load();
  }, [api.measure, api.objective, uid, user]);

  return (
    <ErrorBoundary>
      <div className="people-view-page uk-section uk-section-small">
        <div className="uk-container uk-container-xlarge">
          <ErrorBoundary>
            <Toolbar
              leftControls={
                <ErrorBoundary>
                  <h6 className="uk-title">OVERALL RATING: {getOverall()}</h6>
                </ErrorBoundary>
              }
              rightControls={
                <ErrorBoundary>
                  <button
                    className="btn btn-primary uk-margin-small-right"
                    title="Export your scorecard as PDF."
                    onClick={handleExportPDF}
                  >
                    <span data-uk-icon="icon: file-pdf; ratio:.8" />
                    Export PDF
                  </button>
                  <button
                    className="btn btn-primary uk-margin-small-right"
                    title="Export your scorecard as EXCEL."
                    onClick={handleExportExcel}
                  >
                    <FontAwesomeIcon
                      icon={faFileExcel}
                      size="lg"
                      className="icon uk-margin-small-right"
                    />
                    Export Excel
                  </button>
                  {user && user.disabled && (
                    <button
                      className="btn btn-danger uk-margin-small-right"
                      title="This user is disabled, do you want to archive the scorecard."
                      onClick={onArchiveScorecard}
                    >
                      <FontAwesomeIcon
                        icon={faArchive}
                        size="lg"
                        className="icon uk-margin-small-right"
                      />
                      Archive Scorecard
                      {archiving && <div data-uk-spinner="ratio: .5"></div>}
                    </button>
                  )}
                </ErrorBoundary>
              }
            />
          </ErrorBoundary>
          <ErrorBoundary>{loading && <LoadingEllipsis />}</ErrorBoundary>
          <ErrorBoundary>
            {!loading && uid && <StrategicList objectives={objectives} />}
          </ErrorBoundary>
        </div>
      </div>
      <ErrorBoundary>
        <Modal
          modalId={MODAL_NAMES.EXECUTION.MAP_OVERVIEW_MODAL}
          cssClass="uk-modal-container"
        >
          <StrategicMapObjectiveModal />
        </Modal>
      </ErrorBoundary>
    </ErrorBoundary>
  );
});

export default PeopleView;
