import writeXlsxFile from "write-excel-file";
import { currencyFormat, dataFormat } from "../../../shared/functions/Directives";
import { IEmployeeTableRowItem } from "../../../shared/functions/scorecard-pdf/IndividualPerformanceAgreementDocument";
import { fullPerspectiveName } from "../../../shared/interfaces/IPerspectiveTabs";
import { IMeasure } from "../../../shared/models/Measure";
import { IObjective } from "../../../shared/models/Objective";

const EMPLOYEE_HEADER_ROW: any = [
  {
    value: "Perspectives",
    fontWeight: "bold",
    backgroundColor: "#F4B084",
  },
  {
    value: "Contributory Departmental Objective",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
    wrap: true,
  },
  {
    value: "Individual Scorecard Contribution",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
    wrap: true,
  },
  {
    value: "Weight",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
  {
    value: "Measures/KPI",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
  {
    value: "Baseline",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
  {
    value: "Annual Target",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
  {
    value: "Rating Scale 1-5",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
  {
    value: "Key Initiatives",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
  {
    value: "Target Date",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
  {
    value: "Midterm Rating",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
  {
    value: "Midterm Supervisor Rating",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
  {
    value: "Assessment Rating",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
  {
    value: "Assessment Supervisor Rating",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
  {
    value: "Source of Evidence",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
    wrap: true,
  },
  {
    value: "Comments",
    fontWeight: "bold",
    backgroundColor: "#FFCC00",
  },
];

const SCORECARD_COLUMNS = [
  { width: 10 }, // Perspectives
  { width: 40 }, // Objectives
  { width: 40 }, // Contributory Objectives
  { width: 10 }, // Weight
  { width: 25 }, // Measures/KPI
  { width: 15 }, // Baseline
  { width: 15 }, // Annual Target
  { width: 15 }, // Rating Scale 1-5
  { width: 40 }, // Key Initiatives
  { width: 15 }, // Target Date
  { width: 15 }, // Midterm Rating
  { width: 15 }, // Midterm Supervisor Rating
  { width: 15 }, // Assessment Rating
  { width: 15 }, // Assessment Supervisor Rating
  { width: 25 }, // Source of Evidence
  { width: 40 }, // Comments
];

const EmployeeExcelDefinition = (
  objectives: IObjective[],
  contributoryObjectives: IObjective[],
  measures: IMeasure[]
): IEmployeeTableRowItem[] => {
  const tableRows: IEmployeeTableRowItem[] = measures.map((m) => {
    // get contributory objective
    const contributory = contributoryObjectives.find(
      (o) => o.id === m.objective
    );

    // get strategic objective
    const strategic = objectives.find((s) => {
      if (!contributory) return false;
      return s.id === contributory.parent;
    });

    const ratingScale = () => {
      const r1 = m.rating1
        ? `1 = ${dataFormat(m.dataType, m.rating1, m.dataSymbol)}`
        : "";
      const r2 = m.rating2
        ? `2 = ${dataFormat(m.dataType, m.rating2, m.dataSymbol)}`
        : "";
      const r3 = m.rating3
        ? `3 = ${dataFormat(m.dataType, m.rating3, m.dataSymbol)}`
        : "";
      const r4 = m.rating4
        ? `4 = ${dataFormat(m.dataType, m.rating4, m.dataSymbol)}`
        : "";
      const r5 = m.rating5
        ? `5 = ${dataFormat(m.dataType, m.rating5, m.dataSymbol)}`
        : "";

      const scale = `${r1}\n${r2}\n${r3}\n${r4}\n${r5}`;
      return scale;
    };
   
     const ratingScaleRatios = () => {
       const r1 = m.rating1Ratio !== "" ? `1 = ${m?.rating1Ratio}` : `1 = 0`;
       const r2 = m.rating2Ratio !== "" ? `2 = ${m?.rating2Ratio}` : `2 = 0`;
       const r3 = m.rating3Ratio !== "" ? `3 =  ${m?.rating3Ratio}` : `3 = 0`;
       const r4 = m.rating4Ratio !== "" ? `4 =  ${m?.rating4Ratio}` : `4 = 0`;
       const r5 = m.rating5Ratio !== "" ? `5 =  ${m?.rating5Ratio}` : `5 = 0`;

       const scale = `${r1}\n${r2}\n${r3}\n${r4}\n${r5}`;
       return scale;
     };


    const row: IEmployeeTableRowItem = {
      perspective: fullPerspectiveName(
        contributory ? contributory.perspective : ""
      ),
      strategicObjective: strategic ? strategic.description : "unknown",
      contributoryObjective: contributory ? contributory.description : "unkown",
      weight: contributory ? contributory.weight || 0 : 0,
      measure: m ? m.description : "unknown",
      baseline:
        m && m.dataType === "Ratios"
          ? (m.baselineRatio as string) // Ensure m.baselineRatio is considered a string here
          : m
          ? m.baseline
            ? m.baseline
            : "-"
          : "",

      annualTarget:
        m && m.dataType === "Ratios"
          ? (m.annualTargetRatio as string)
          : m
          ? m.annualTarget
            ? m.annualTarget
            : "-"
          : "",
      ratingScale:
        m && m.dataType === "Ratios" ? ratingScaleRatios() : ratingScale(),
      keyInitiatives: m ? m.activities || "-" : "unkown",
      targetDate: m ? m.targetDate : "",
      midtermAutoRating: m ? m.midtermAutoRating : 0,
      midtermRating: m ? m.midtermRating : 0,
      autoRating: m ? m.autoRating : 0,
      finalRating: m ? m.finalRating : 0,
      sourceOfEvidence: m ? m.sourceOfEvidence || "-" : "unknown",
      comments: m ? m.comments : "",
      dataType: m.dataType,
      dataSymbol: m.dataSymbol,
    };

    return row;
  });

  const sortByPerspective = (
    a: IEmployeeTableRowItem,
    b: IEmployeeTableRowItem
  ) => {
    const order = ["F", "C", "I", "L"];
    const aIndex = order.indexOf(a.perspective.charAt(0));
    const bIndex = order.indexOf(b.perspective.charAt(0));
    return (
      aIndex - bIndex ||
      a.strategicObjective.localeCompare(b.strategicObjective) ||
      a.contributoryObjective.localeCompare(b.contributoryObjective)
    );
  };
  return tableRows.sort(sortByPerspective);
};
export const currencySymbols = {
  NAD: "NAD",
  USD: "USD",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  AUD: "AUD",
  CAD: "CAD",
  CHF: "CHF",
  NZD: "NZD",
  YEN: "YEN",
} as const;

export type Currency = keyof typeof currencySymbols;
function isCurrencySymbol(value: any): value is Currency {
  return Object.keys(currencySymbols).includes(value);
}


type FormatColumnParams = {
  value: any;
  type?:
    | string
    | "Date"
    | "Percentage"
    | "Number"
    | "Currency"
    | "Time"
    | "String"
    | "Ratios"
    | "Custom";
  backgroundColor?: string;
  rowSpan?: number;
  currency?: Currency;
};
const formatColumn = ({
  value,
  type = "String",
  backgroundColor,
  rowSpan,
  currency,
}: FormatColumnParams) => {
  console.log(`Formatting column with type: ${type} and value: ${value}`);
  switch (type) {
    case "Currency":
      return {
        type: String,
        value: currencyFormat(value, currency) || null,
        alignVertical: "top",
        backgroundColor: backgroundColor,
        borderColor: "#55555",
        rowSpan: rowSpan,
      };

    case "Number":
      return {
        type: Number,
        format: "#,##0",
        value: Number(value) || null,
        alignVertical: "top",
        backgroundColor: backgroundColor,
        borderColor: "#55555",
        rowSpan: rowSpan,
      };

    case "Percentage":
      return {
        type: Number,
        format: "0.0%",
        value: Number(value) / 100 || null,
        wrap: true,
        alignVertical: "top",
        backgroundColor: backgroundColor,
        borderColor: "#55555",
        rowSpan: rowSpan,
      };

    case "Date":
      if (!value) {
        return {
          type: String,
          value: value || " - ",
          wrap: true,
          alignVertical: "top",
          backgroundColor: backgroundColor,
          borderColor: "#55555",
          rowSpan: rowSpan,
        };
      } else
        return {
          type: Date,
          value: new Date(value) || null,
          format: "dd mmmm yyyy",
          wrap: true,
          alignVertical: "top",
          backgroundColor: backgroundColor,
          borderColor: "#55555",
          rowSpan: rowSpan,
        };

    case "Time":
      return {
        type: String,
        value: value.toString() || " - ",
        wrap: true,
        alignVertical: "top",
        backgroundColor: backgroundColor,
        borderColor: "#55555",
        rowSpan: rowSpan,
      };

    default:
      return {
        type: String,
        value: value !== undefined || value !== 0 ? value.toString() : "-", //value || " - ",
        wrap: true,
        alignVertical: "top",
        backgroundColor: backgroundColor,
        borderColor: "#55555",
        rowSpan: rowSpan,
      };
  }
};

export const exportEmployeeScorecardExcel = async (
  title: string,
  objectives: IObjective[],
  contributoryObjectives: IObjective[],
  measures: IMeasure[]
) => {
  // Get the rows
  const rows = EmployeeExcelDefinition(
    objectives,
    contributoryObjectives,
    measures
  );

  let perspective = "";
  let objective = "";
  let cObjective = "";

  const dataRows = rows.map((row, _, data) => {
    let perspectiveRowSpan = undefined;
    let objectiveRowSpan = undefined;
    let cObjectiveRowSpan = undefined;

    if (perspective !== row.perspective) {
      perspective = row.perspective;
      perspectiveRowSpan = data.filter(
        (r) => r.perspective === perspective
      ).length;
    }

    if (objective !== row.strategicObjective) {
      objective = row.strategicObjective;
      objectiveRowSpan = data.filter(
        (r) => r.strategicObjective === objective
      ).length;
    }

    if (cObjective !== row.contributoryObjective) {
      cObjective = row.contributoryObjective;
      cObjectiveRowSpan = data.filter(
        (r) => r.contributoryObjective === cObjective
      ).length;
    }
   const currency = isCurrencySymbol(row.dataSymbol)
     ? row.dataSymbol
     : undefined;

    return [
      formatColumn({
        value: row.perspective,
        backgroundColor: "#F4B084",
        rowSpan: perspectiveRowSpan,
      }), // Perspectives
      formatColumn({
        value: row.strategicObjective,
        rowSpan: objectiveRowSpan,
      }), // Objectives
      formatColumn({
        value: row.contributoryObjective,
        rowSpan: cObjectiveRowSpan,
      }), // Contributory Objectives
      formatColumn({
        value: row.weight,
        type: "General",
        rowSpan: cObjectiveRowSpan,
      }), // Weight
      formatColumn({ value: row.measure }), // Measures/KPI
      formatColumn({
        value: row.baseline,
        type: row.dataType, //if general, the number dates are not converted to date TO DO:
        currency: currency,
      }), // Baseline //if general, the number dates are not converted to date TO DO:
      formatColumn({
        value: row.annualTarget,
        type: row.dataType,
        currency: currency,
      }), // Annual Target
      formatColumn({ value: row.ratingScale }), // Rating scale 1-5
      formatColumn({ value: row.keyInitiatives }), // Key Initiatives
      formatColumn({ value: row.targetDate, type: "Date" }), // Target Date
      formatColumn({ value: row.midtermAutoRating, type: "Number" }), // Midterm Rating
      formatColumn({ value: row.midtermRating, type: "Number" }), // Midterm Supervisor Rating
      formatColumn({ value: row.autoRating, type: "Number" }), // Assessement Rating
      formatColumn({ value: row.finalRating, type: "Number" }), // Assessement Supervisor Rating
      formatColumn({ value: row.sourceOfEvidence }), // Source of evidence
      formatColumn({ value: row.comments }), // Comments
    ];
  });

  const data = [EMPLOYEE_HEADER_ROW, ...dataRows];

  await writeXlsxFile(data, {
    columns: SCORECARD_COLUMNS, // (optional) column widths, etc.
    fileName: `${title}.xlsx`,
    sheet: "Data",
    fontFamily: "Candara",
    fontSize: 12,
  });
};
