import React from "react";

interface RatioDisplayProps {
  value: string | null;
  dataSymbol: string;
  dataType: string;
}

const RatioDisplay: React.FC<RatioDisplayProps> = ({
  value,
  dataSymbol,
  dataType,
}) => {
  if (value === null) {
    return <span>N/A</span>; // or any placeholder for null values
  }

  if (dataType === "ratios") {
    // Display ratio as is
    return (
      <span>
        {value} {dataSymbol}
      </span>
    );
  }

  return (
    <span>
      {value} {dataSymbol}
    </span>
  );
};

export default RatioDisplay;
