import { observer } from "mobx-react-lite";
import { Navigate, useLocation } from "react-router-dom";
import ErrorBoundary from "../shared/components/error-boundary/ErrorBoundary";
import { LoadingEllipsis } from "../shared/components/loading/Loading";
import { useAppContext } from "../shared/functions/Context";
import "./LoggedOut.scss";

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

const Loader = () => {
  return (
    <div style={style}>
      <LoadingEllipsis />
    </div>
  );
};

type ILocationState = {
  from: string;
};

const LoggedOut = observer(() => {
  const { api, store } = useAppContext();
  const location = useLocation();

  const redirectSignIn = () => {
    api.auth.logInWithPopup();
  };

  if (store.auth.loading) return <Loader />;

  if (!store.auth.loading && store.auth.me) {
    // console.log(store.auth.meJson?.uid);

    const state = location.state as ILocationState;

    if (state) return <Navigate to={state.from} />;
    return <Navigate to="/c/home/dashboard" />;
  }

  return (
    <ErrorBoundary>
      <div className="logged-out"
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/DSC00496-Edit-min.jpg)`, }}>
        <div className="login uk-card uk-card-secondary">
          <div className="header uk-card-header">
            <h3 className="uk-card-title">
              NAMCOR Performance Management System
            </h3>
          </div>
          <div className="body uk-card-body">
            <div className="brand">
              <img src={process.env.PUBLIC_URL + "/logo512.png"} alt="" />
            </div>
          </div>
          <div className="controls">
            <button className="btn btn-primary" onClick={redirectSignIn}>
              Login with AD Account
            </button>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
});

export default LoggedOut;
