import { dataTypeSymbol } from "../../../logged-in/shared/functions/Scorecard";
import { dateFormat } from "../../../logged-in/shared/utils/utils";
import { fullPerspectiveName } from "../../interfaces/IPerspectiveTabs";
import { IMeasureDepartment } from "../../models/MeasureDepartment";
import { IObjective } from "../../models/Objective";
import { dataFormat } from "../Directives";
import {
  marginTopBottom,
  header,
  sectionHeader,
  departmentSignatureTable,
} from "./DocDefition";
import { brandLogo, footerStripes } from "./ImageLoader";

const tableWidths: Row = [
  70,
  "*",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
  "auto",
];

const tableHeader: Row = [
  { text: "Perspective", style: "tableHeader" },
  { text: "Strategic Objectives", style: "tableHeader" },
  { text: "Contributory Departmental Objective", style: "tableHeader" },
  { text: "Weight (%)", style: "tableHeader" },
  { text: "Measures/KPI", style: "tableHeader" },
  { text: "Baseline", style: "tableHeader" },
  { text: "Quarterly Target , Q1", style: "tableHeader" },
  { text: "Quarterly Target , Q2", style: "tableHeader" },
  { text: "Quarterly Target , Q3", style: "tableHeader" },
  { text: "Quarterly Target , Q4", style: "tableHeader" },
  { text: "Annual Target", style: "tableHeader" },
  { text: "Rating Scale 1-5", style: "tableHeader" },
  { text: "Key Initiatives", style: "tableHeader" },
  { text: "Target Date", style: "tableHeader" },
  { text: "Q1 Employee Rating", style: "tableHeader" },
  { text: "Q1 Supervisor Rating", style: "tableHeader" },
  { text: "Q2 Employee Rating", style: "tableHeader" },
  { text: "Q2 Supervisor Rating", style: "tableHeader" },
  { text: "Q3 Employee Rating", style: "tableHeader" },
  { text: "Q3 Supervisor Rating", style: "tableHeader" },
  { text: "Q4 Employee Rating", style: "tableHeader" },
  { text: "Q4 Supervisor Rating", style: "tableHeader" },
  { text: "Source of Evidence", style: "tableHeader" },
  { text: "Comments", style: "tableHeader" },
];

type RowSpan = {
  text: string | any;
  rowSpan?: number;
  style?: string;
};

type Row = [
  RowSpan | string | number,
  RowSpan | string,
  RowSpan | string,
  RowSpan | string | number,
  RowSpan | string | number,
  RowSpan | string | number,
  RowSpan | string | number,
  RowSpan | string | number,
  RowSpan | string | number,
  RowSpan | string | number,
  RowSpan | string | number,
  RowSpan | string,
  RowSpan | string | number,
  RowSpan | string | number,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  string | number | RowSpan,
  RowSpan | string,
  RowSpan | string
];

export interface IDepartmentAgreementTableRowItem {
  perspective: string; // "Perspective",
  strategicObjective: string; // "Strategic Objectives",
  contributoryObjective: string; // "Contributory Departmental Objective",
  weight: number; // "Weight (%)",
  measure: string | number; // "Measures/KPI",
  baseline: string | number; // "Baseline",
  q1target: string | number; // "Quarterly Target , Q1",
  q2target: string | number; // "Quarterly Target , Q2",
  q3target: string | number; // "Quarterly Target , Q3",
  q4target: string | number; // "Quarterly Target , Q4",
  annualTarget: string | number; // "Annual Target",
  ratingScale: string; // "rating scale",
  keyInitiatives: string | number; // "Key Initiatives",
  targetDate: string | number; // "Target Date",
  q1AutoRating: number | null; // "Q1 Rating",
  q1Rating: number | null; // "Q1 Supervisor Rating",
  q2AutoRating: number | null; // "Q2  Rating",
  q2Rating: number | null; // "Q2 Supervisor Rating"
  q3AutoRating: number | null; // "Q2  Rating",,// "Q3 Rating",
  q3Rating: number | null; // "Q3 Supervisor Rating",
  q4AutoRating: number | null; // "Q4 Rating",
  q4Rating: number | null; // "Q4 Supervisor Rating",
  sourceOfEvidence: string; // "Source of Evidence",
  comments: string; // "Comments",
  dataType?: string; // "Data type"
  dataSymbol?: string; // "Symbol"
}

const ConvertToTableRowItem = (
  strategicObjectives: IObjective[],
  contributoryObjectives: IObjective[],
  measures: IMeasureDepartment[]
) => {
  console.log("Log department score", measures);
  
  const sortByPerspective = (
    a: IDepartmentAgreementTableRowItem,
    b: IDepartmentAgreementTableRowItem
  ) => {
    const order = ["F", "C", "I", "L"];
    const aIndex = order.indexOf(a.perspective.charAt(0));
    const bIndex = order.indexOf(b.perspective.charAt(0));
    return (
      aIndex - bIndex ||
      a.strategicObjective.localeCompare(b.strategicObjective) ||
      a.contributoryObjective.localeCompare(b.contributoryObjective)
    );
  };

const formatMeasureValue = (
  type: string,
  value: string | number | null,
  symbol: string
): string => {
  const { prefix, suffix } = dataTypeSymbol(type);

  if (type === "Ratios") {
    return value !== null && value !== undefined
      ? `${value} ${symbol}`
      : "unknown";
  }

  if (!value && value !== 0) return ""; // Check for null, undefined, or 0

  switch (type) {
    case "Date":
      return dateFormat(Number(value));
    case "Currency":
      return `${prefix}${Number(value).toFixed(2)}${suffix}`;
    default:
      return `${prefix}${value}${suffix}`;
  }
};


  const tableRows: IDepartmentAgreementTableRowItem[] = measures.map((m) => {
    // get contributory objective
    const contributory = contributoryObjectives.find(
      (o) => o.id === m.objective
    );

    // get strategic objective
    const strategic = strategicObjectives.find((s) => {
      if (!contributory) return false;
      return s.id === contributory.parent;
    });

   
      const ratingScale = () => {
        const r1 =
          m.rating1 !== 0
            ? `1 = ${dataFormat(m.dataType, m.rating1, m.dataSymbol)}`
            : `1 = 0`;
        const r2 =
          m.rating2 !== 0
            ? `2 = ${dataFormat(m.dataType, m.rating2, m.dataSymbol)}`
            : `2 = 0`;
        const r3 =
          m.rating3 !== 0
            ? `3 = ${dataFormat(m.dataType, m.rating3, m.dataSymbol)}`
            : `3 = 0`;
        const r4 =
          m.rating4 !== 0
            ? `4 = ${dataFormat(m.dataType, m.rating4, m.dataSymbol)}`
            : `4 = 0`;
        const r5 =
          m.rating5 !== 0
            ? `5 = ${dataFormat(m.dataType, m.rating5, m.dataSymbol)}`
            : `5 = 0`;

        const scale = `${r1}\n${r2}\n${r3}\n${r4}\n${r5}`;
        return scale;
      };
      const ratingScaleRatios = () => {
        const r1 = m.rating1Ratio !== "" ? `1 = ${m?.rating1Ratio}` : `1 = 0`;
        const r2 = m.rating2Ratio !== "" ? `2 = ${m?.rating2Ratio}` : `2 = 0`;
        const r3 = m.rating3Ratio !== "" ? `3 =  ${m?.rating3Ratio}` : `3 = 0`;
        const r4 = m.rating4Ratio !== "" ? `4 =  ${m?.rating4Ratio}` : `4 = 0`;
        const r5 = m.rating5Ratio !== "" ? `5 =  ${m?.rating5Ratio}` : `5 = 0`;

        const scale = `${r1}\n${r2}\n${r3}\n${r4}\n${r5}`;
        return scale;
      };


 
   
    const row: IDepartmentAgreementTableRowItem = {
      perspective: fullPerspectiveName(
        contributory ? contributory.perspective : ""
      ),
      strategicObjective: strategic ? strategic.description : "unknown",
      contributoryObjective: contributory ? contributory.description : "unkown",
      weight: contributory ? contributory.weight || 0 : 0,
      measure: m ? m.description : "unkown",
      baseline:
        m && m.dataType === "Ratios"
          ? (m.baselineRatio as string) // Ensure m.baselineRatio is considered a string here
          : formatMeasureValue(m.dataType, m.baseline, m.dataSymbol),
      q1target:
        m && m.dataType === "Ratios"
          ? (m.quarter1TargetRatio as string)
          : formatMeasureValue(m.dataType, m.quarter1Target, m.dataSymbol),
      q2target:
        m && m.dataType === "Ratios"
          ? (m.quarter2TargetRatio as string)
          : formatMeasureValue(m.dataType, m.quarter2Target, m.dataSymbol),
      q3target:
        m && m.dataType === "Ratios"
          ? (m.quarter3TargetRatio as string)
          : formatMeasureValue(m.dataType, m.quarter3Target, m.dataSymbol),
      q4target:
        m && m.dataType === "Ratios"
          ? (m.quarter4TargetRatio as string)
          : formatMeasureValue(m.dataType, m.quarter4Target, m.dataSymbol),
      annualTarget:
        m && m.dataType === "Ratios"
          ? (m.annualTargetRatio as string)
          : formatMeasureValue(m.dataType, m.annualTarget, m.dataSymbol),
      ratingScale:
        m && m.dataType === "Ratios" ? ratingScaleRatios() : ratingScale(),

      keyInitiatives: m ? m.activities || "-" : "unknown",
      targetDate: m ? m.targetDate : "unknown",
      q1AutoRating: m ? m.q1AutoRating : 0,
      q1Rating: m ? m.q1Rating : 0,
      q2AutoRating: m ? m.q2AutoRating : 0,
      q2Rating: m ? m.q2Rating : 0,
      q3AutoRating: m ? m.q3AutoRating : 0,
      q3Rating: m ? m.q3Rating : 0,
      q4AutoRating: m ? m.q4AutoRating : 0,
      q4Rating: m ? m.q4Rating : 0,
      sourceOfEvidence: m ? m.sourceOfEvidence || "-" : "unknown",
      comments: m ? m.comments : "unknown",
      dataType: m ? m.dataType : "", // Ensure dataType is defined appropriately
      dataSymbol: m ? m.dataSymbol : "", // Ensure dataSymbol is defined appropriately
    };


    return row;
  });

  return tableRows.sort(sortByPerspective);
};

const FormatTableSpan = (_rows: IDepartmentAgreementTableRowItem[]) => {
  let perspective = "";
  let objective = "";
  let cObjective = "";

  const rows: Row[] = _rows.map((row, _, data) => {
    let perspectiveRowSpan = undefined;
    let objectiveRowSpan = undefined;
    let cObjectiveRowSpan = undefined;

    if (perspective !== row.perspective) {
      perspective = row.perspective;
      perspectiveRowSpan = data.filter(
        (r) => r.perspective === perspective
      ).length;
    }

    if (objective !== row.strategicObjective) {
      objective = row.strategicObjective;
      objectiveRowSpan = data.filter(
        (r) => r.strategicObjective === objective
      ).length;
    }

    if (cObjective !== row.contributoryObjective) {
      cObjective = row.contributoryObjective;
      cObjectiveRowSpan = data.filter(
        (r) => r.contributoryObjective === cObjective
      ).length;
    }

    return [
      {
        rowSpan: perspectiveRowSpan || 1,
        text: row.perspective,
        fillColor: "#dedede",
      },
      {
        rowSpan: objectiveRowSpan || 1,
        text: row.strategicObjective,
      },
      {
        rowSpan: cObjectiveRowSpan || 1,
        text: row.contributoryObjective,
      },
      {
        rowSpan: cObjectiveRowSpan || 1,
        text: row.weight || "-",
      },
      row.measure || "-",
      row.baseline || "-",
      row.q1target || "-",
      row.q2target || "-",
      row.q3target || "-",
      row.q4target || "-",
      row.annualTarget || "-",
      row.ratingScale || "-",
      row.keyInitiatives || "-",
      row.targetDate || "-",
      { text: row.q1AutoRating || 0, fillColor: "#fcecec" },
      { text: row.q1Rating || 0, fillColor: "#fcecec" },
      { text: row.q2AutoRating || 0, fillColor: "#caf1de" },
      { text: row.q2Rating || 0, fillColor: "#caf1de" },
      { text: row.q3AutoRating || 0, fillColor: "#fcecec" },
      { text: row.q3Rating || 0, fillColor: "#fcecec" },
      { text: row.q4AutoRating || 0, fillColor: "#caf1de" },
      { text: row.q4Rating || 0, fillColor: "#caf1de" },
      row.sourceOfEvidence || "-",
      row.comments || "-",
    ];
  });

  return rows;
};

export const DepartmentPerformanceAgreementDocument = async (
  title: string,
  vision: string,
  mission: string,
  strategicObjectives: IObjective[],
  contributoryObjectives: IObjective[],
  measures: IMeasureDepartment[]
) => {
  const logo = await brandLogo();
  const footer = await footerStripes();

  const rows: IDepartmentAgreementTableRowItem[] = ConvertToTableRowItem(
    strategicObjectives,
    contributoryObjectives,
    measures
  );
  const mappedRows = FormatTableSpan(rows);
  const body = [tableHeader, ...mappedRows];

  return {
    pageSize: "A2", // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: "landscape",
    footer,
    content: [
      logo,
      marginTopBottom(),
      header(title),
      marginTopBottom(),
      sectionHeader("Mission:"),
      sectionHeader(mission),
      marginTopBottom(),
      sectionHeader("Vision:"),
      sectionHeader(vision),
      marginTopBottom(),

      {
        // layout: "lightHorizontalLines", // optional
        logo,
        table: {
          headerRows: 1,
          widths: tableWidths,
          body: body,
        },
      },
      marginTopBottom(),
      marginTopBottom(),
      departmentSignatureTable(),
    ],
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: "black",
      },
    },
  };
};
