import { observer } from "mobx-react-lite";
import { useAppContext } from "../../shared/functions/Context";
import NodeRow from "./NodeRow";
import Node from "./Node";
import "./StrategicMap.scss";
import {
  CUSTOMER_TAB,
  FINANCIAL_TAB,
  GROWTH_TAB,
  PROCESS_TAB,
} from "../../shared/interfaces/IPerspectiveTabs";
import VisionMission from "../../shared/components/vision-mission/VisionMission";
import ErrorBoundary from "../../shared/components/error-boundary/ErrorBoundary";
import { IObjective } from "../../shared/models/Objective";

const StrategicMap = observer(() => {
  const { store } = useAppContext();

  // const _operational = store.strategicTheme.all.find((theme) => theme.asJson.orderNo === 1)
  // const _supply = store.strategicTheme.all.find((theme) => theme.asJson.orderNo === 2)
  // const _harness = store.strategicTheme.all.find((theme) => theme.asJson.orderNo === 3)
  // const _digigtal = store.strategicTheme.all.find((theme) => theme.asJson.orderNo === 4)

    const _operational = store.strategicTheme.all[0]; // Assuming index 0 corresponds to operational
    const _supply = store.strategicTheme.all[1]; // Assuming index 1 corresponds to supply
    const _harness = store.strategicTheme.all[2]; // Assuming index 2 corresponds to harness
    const _digital = store.strategicTheme.all[3];

  const THEMES = {
    OPERATIONAL: _operational ? _operational.asJson.id : "",
    SUPPLY: _supply ? _supply.asJson.id : "",
    HARNESS: _harness ? _harness.asJson.id : "",
    DIGITAL: _digital ? _digital.asJson.id : "",
  };

  const THEMES_NAMES = {
    OPERATIONAL: _operational ? _operational.asJson.description : "",
    SUPPLY: _supply ? _supply.asJson.description : "",
    HARNESS: _harness ? _harness.asJson.description : "",
    DIGITAL: _digital ? _digital.asJson.description : "",
  };

  // const THEMES = {
  //   OPERATIONAL: "cckbTvOQmb8e9E16aD7v",
  //   SUPPLY: "nCEB8zLB5Rs9NmqbNI5I",
  //   HARNESS: "QlEg7TyHvKcbHa2ZzLkB",
  //   DIGITAL: "2vtqMIg9jX90UXKSukCY",
  // };


  const getObjectivesPerPerspective = (perspective: string) => {
    return store.departmentObjective.all.filter((objective) => objective.asJson.perspective === perspective)
      .map((objective) => objective.asJson);
  };

  const getByTheme = (objectives: IObjective[], theme: string) => {
    return objectives.filter((objective) => objective.theme === theme);
  };

  return (
    <div className="strategic-map uk-card uk-card-default uk-card-body uk-card-small uk-margin">
      <div className="map">
        <VisionMission />
        <table className="objectives">
          <thead>
            <th></th>
            <th>{THEMES_NAMES.OPERATIONAL}</th>
            <th>{THEMES_NAMES.SUPPLY}</th>
            <th>{THEMES_NAMES.HARNESS}</th>
            <th>{THEMES_NAMES.DIGITAL}</th>
          </thead>
          <tbody>
            <NodeRow
              perspective="Financial"
              operational={getByTheme(
                getObjectivesPerPerspective(FINANCIAL_TAB.id),
                THEMES.OPERATIONAL
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              supply={getByTheme(
                getObjectivesPerPerspective(FINANCIAL_TAB.id),
                THEMES.SUPPLY
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              harness={getByTheme(
                getObjectivesPerPerspective(FINANCIAL_TAB.id),
                THEMES.HARNESS
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              digital={getByTheme(
                getObjectivesPerPerspective(FINANCIAL_TAB.id),
                THEMES.DIGITAL
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
            />

            <NodeRow
              perspective="Customer"
              operational={getByTheme(
                getObjectivesPerPerspective(CUSTOMER_TAB.id),
                THEMES.OPERATIONAL
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              supply={getByTheme(
                getObjectivesPerPerspective(CUSTOMER_TAB.id),
                THEMES.SUPPLY
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              harness={getByTheme(
                getObjectivesPerPerspective(CUSTOMER_TAB.id),
                THEMES.HARNESS
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              digital={getByTheme(
                getObjectivesPerPerspective(CUSTOMER_TAB.id),
                THEMES.DIGITAL
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
            />

            <NodeRow
              perspective="Internal Process"
              operational={getByTheme(
                getObjectivesPerPerspective(PROCESS_TAB.id),
                THEMES.OPERATIONAL
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              supply={getByTheme(
                getObjectivesPerPerspective(PROCESS_TAB.id),
                THEMES.SUPPLY
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              harness={getByTheme(
                getObjectivesPerPerspective(PROCESS_TAB.id),
                THEMES.HARNESS
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              digital={getByTheme(
                getObjectivesPerPerspective(PROCESS_TAB.id),
                THEMES.DIGITAL
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
            />

            <NodeRow
              perspective="Learning and Growth"
              operational={getByTheme(
                getObjectivesPerPerspective(GROWTH_TAB.id),
                THEMES.OPERATIONAL
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              supply={getByTheme(
                getObjectivesPerPerspective(GROWTH_TAB.id),
                THEMES.SUPPLY
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              harness={getByTheme(
                getObjectivesPerPerspective(GROWTH_TAB.id),
                THEMES.HARNESS
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
              digital={getByTheme(
                getObjectivesPerPerspective(GROWTH_TAB.id),
                THEMES.DIGITAL
              ).map((objective) => (
                <ErrorBoundary key={objective.id}>
                  <Node objective={objective} />
                </ErrorBoundary>
              ))}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default StrategicMap;
