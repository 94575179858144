import { observer } from "mobx-react-lite";
import ErrorBoundary from "../../../shared/components/error-boundary/ErrorBoundary";

import { SetStateAction, useState } from "react";
import { ITAB_ID } from "../../../shared/interfaces/IPerspectiveTabs";
import useTitle from "../../../shared/hooks/useTitle";
import FormsTabs from "../../shared/components/tabs/FormTabs";
import { useAppContext } from "../../../shared/functions/Context";
import { DownloadDocs } from "./DownloadDocuments";
import { UploadDocs } from "./UploadDocuments";

interface ITabsProps {
  tab: "DownloadDocs" | "UploadDocs";
  setTab: React.Dispatch<React.SetStateAction<"DownloadDocs" | "UploadDocs">>;
}

const Tabs = observer((props: ITabsProps) => {
  const { store } = useAppContext();

  const activeClass = (tab: "DownloadDocs" | "UploadDocs") => {
    if (props.tab === tab) return "uk-active";
    return "";
  };

  return (
    <div className="dashboard--tabs">
      <ul className="kit-tabs" data-uk-tab>
        <li
          className={activeClass("DownloadDocs")}
          onClick={() => props.setTab("DownloadDocs")}
        >
          <a href="#">Download Documents</a>
        </li>
        <li
          className={activeClass("UploadDocs")}
          onClick={() => props.setTab("UploadDocs")}
        >
          <a href="#">Upload Documents</a>
        </li>
      </ul>
    </div>
  );
});

export const Forms = observer(() => {
  const [tab, setTab] = useState<"DownloadDocs" | "UploadDocs">("DownloadDocs");
  useTitle("Change Request Form");
  const [loading, setLoading] = useState(false);

  return (
    <ErrorBoundary>
      <div className="dashboard uk-section uk-section-small">
        <div className="uk-container uk-container-xlarge">
          <ErrorBoundary>
            <Tabs tab={tab} setTab={setTab} />
          </ErrorBoundary>

          <ErrorBoundary>
            {tab === "DownloadDocs" && !loading && <DownloadDocs />}

            {tab === "UploadDocs" && <UploadDocs />}
          </ErrorBoundary>
        </div>
      </div>
    </ErrorBoundary>
  );
});
