import React, { useEffect, useMemo, useState } from "react";
import Toolbar from "../shared/components/toolbar/Toolbar";
import Modal from "../../shared/components/Modal";
import MODAL_NAMES from "../dialogs/ModalName";
import showModalFromId from "../../shared/functions/ModalShow";
import { observer } from "mobx-react-lite";
import { useAppContext } from "../../shared/functions/Context";
import { IObjective } from "../../shared/models/Objective";
import { useNavigate, useParams } from "react-router-dom";
import useTitle from "../../shared/hooks/useTitle";
import WeightError from "../shared/components/weight-error/WeightError";
import useBackButton from "../../shared/hooks/useBack";
import Dropdown from "../../shared/components/dropdown/Dropdown";
import ErrorBoundary from "../../shared/components/error-boundary/ErrorBoundary";
import { LoadingEllipsis } from "../../shared/components/loading/Loading";
import { dataFormat } from "../../shared/functions/Directives";
import useIndividualScorecard from "../../shared/hooks/useIndividualScorecard";
import { IMeasure, defaultMeasure } from "../../shared/models/Measure";
import NoMeasures from "../execution-scorecard/NoMeasures";
import { IScorecardMetadata } from "../../shared/models/ScorecardMetadata";
import RatioDisplay from "../company-scorecard-objective/RatioDisplay";
const MeasureModal = React.lazy(() => import("../dialogs/measure/MeasureModal"));
const MeasureCommentsModal = React.lazy(() => import("../dialogs/measure-comments/MeasureCommentsModal"));

interface IMeasureTableItemProps {
  measure: IMeasure;
  agreement: IScorecardMetadata;
  loading: boolean;
}
const MeasureTableItem = observer((props: IMeasureTableItemProps) => {
  const { api, store } = useAppContext();
  const { measure, agreement, loading } = props;

  const [currentMeasure, setCurrentMeasure] = useState<IMeasure>({ ...defaultMeasure, });

  const dataType = measure.dataType;
  const dataSymbol = measure.dataSymbol;

  const enableEditing = () => {
    const isEditing = agreement.agreementDraft.status === "pending" ||
      agreement.agreementDraft.status === "in-progress"
    return !isEditing;
  };

  const handleEditComments = () => {
    store.measure.select(currentMeasure); // select measure
    showModalFromId(MODAL_NAMES.EXECUTION.MEASURE_COMMENTS_MODAL);
  };

  const handleEditMeasure = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    store.measure.select(currentMeasure); // select measure
    showModalFromId(MODAL_NAMES.EXECUTION.MEASURE_MODAL);
  };

  const handleDeleteMeasure = () => {
    if (!window.confirm("Remove measure?")) return;
    api.measure.delete(currentMeasure);
  };

  // update current measure
  useEffect(() => {
    setCurrentMeasure({ ...measure });
  }, [measure]);

  if (loading)
    return (
      <ErrorBoundary>
        <LoadingEllipsis />
      </ErrorBoundary>
    );

    const renderTableCells = () => {
    if (dataType === "Ratios") {
      return (
        <>
          <td className="no-whitespace">
            <RatioDisplay
              value={measure?.baselineRatio ?? null}
              dataSymbol={dataSymbol}
              dataType={dataType}
            />
          </td>
          <td className="no-whitespace">
            <RatioDisplay
              value={measure.annualTargetRatio ?? null}
              dataSymbol={dataSymbol}
              dataType={dataType}
            />
          </td>
          <td className="no-whitespace">
            <RatioDisplay
              value={measure.rating1Ratio ?? null}
              dataSymbol={dataSymbol}
              dataType={dataType}
            />
          </td>
          <td className="no-whitespace">
            <RatioDisplay
              value={measure.rating2Ratio ?? null}
              dataSymbol={dataSymbol}
              dataType={dataType}
            />
          </td>
          <td className="no-whitespace">
            <RatioDisplay
              value={measure.rating3Ratio ?? null}
              dataSymbol={dataSymbol}
              dataType={dataType}
            />
          </td>
          <td className="no-whitespace">
            <RatioDisplay
              value={measure.rating4Ratio ?? null}
              dataSymbol={dataSymbol}
              dataType={dataType}
            />
          </td>
          <td className="no-whitespace">
            <RatioDisplay
              value={measure.rating5Ratio ?? null}
              dataSymbol={dataSymbol}
              dataType={dataType}
            />
          </td>
          <td>
            <div className="controls">
              {/* {unsavedChanges && (
            <button
              className="btn-icon uk-margin-small-right"
              onClick={handleUpdate}
            >
              <span uk-icon="push"></span>
            </button>
          )} */}
              <button className="btn-icon">
                <span uk-icon="more"></span>
              </button>
              <Dropdown>
                <li>
                  <button
                    className="kit-dropdown-btn"
                    onClick={handleEditMeasure}
                    disabled={enableEditing()}>
                    <span uk-icon="pencil"></span> Edit Measure
                  </button>
                </li>
                <li>
                  <button
                    className="kit-dropdown-btn"
                    onClick={handleDeleteMeasure}
                    disabled={enableEditing()}>
                    <span uk-icon="trash"></span> Delete Measure
                  </button>
                </li>
              </Dropdown>
            </div>
          </td>
        </>
      );
    } else {
      return (
        <>
          <td className="no-whitespace">
            {dataFormat(dataType, measure.baseline, dataSymbol)}
          </td>
          <td className="no-whitespace">
            {dataFormat(dataType, measure.annualTarget, dataSymbol)}
          </td>
          <td className="no-whitespace">
            {dataFormat(dataType, measure.rating1, dataSymbol)}
          </td>
          <td className="no-whitespace">
            {dataFormat(dataType, measure.rating2, dataSymbol)}
          </td>
          <td className="no-whitespace">
            {dataFormat(dataType, measure.rating3, dataSymbol)}
          </td>
          <td className="no-whitespace">
            {dataFormat(dataType, measure.rating4, dataSymbol)}
          </td>
          <td className="no-whitespace">
            {dataFormat(dataType, measure.rating5, dataSymbol)}
          </td>
          <td>
            <div className="controls">
              {/* {unsavedChanges && (
            <button
              className="btn-icon uk-margin-small-right"
              onClick={handleUpdate}
            >
              <span uk-icon="push"></span>
            </button>
          )} */}
              <button className="btn-icon">
                <span uk-icon="more"></span>
              </button>
              <Dropdown>
                <li>
                  <button
                    className="kit-dropdown-btn"
                    onClick={handleEditMeasure}
                    disabled={enableEditing()}>
                    <span uk-icon="pencil"></span> Edit Measure
                  </button>
                </li>
                <li>
                  <button
                    className="kit-dropdown-btn"
                    onClick={handleDeleteMeasure}
                    disabled={enableEditing()}>
                    <span uk-icon="trash"></span> Delete Measure
                  </button>
                </li>
              </Dropdown>
            </div>
          </td>
        </>
      );
    }
  };

  return (
    <tr className="row">
      <td>{measure.description}</td>
      {renderTableCells()}
    </tr>
  );
});




interface IMeasureTableProps {
  measures: IMeasure[];
  agreement: IScorecardMetadata;
  loading: boolean;
}
const MeasureTable = observer((props: IMeasureTableProps) => {
  const { measures, agreement, loading } = props;
  const [isEmpty, setisEmpty] = useState(false);

  useEffect(() => {
    setisEmpty(measures.length === 0 ? true : false);
  }, [measures]);

  return (
    <ErrorBoundary>
      <div className="measure-table">
        {!isEmpty && (
          <table className="measure-table uk-table uk-table-small uk-table-middle uk-table-hover uk-table-divider">
            <thead className="header">
              <tr>
                <th className="uk-width-expand@s">Measure/KPI</th>
                <th>Baseline</th>
                <th>Annual Target</th>
                <th>Rate 1</th>
                <th>Rate 2</th>
                <th>Rate 3</th>
                <th>Rate 4</th>
                <th>Rate 5</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {measures.map((measure) => (
                <ErrorBoundary key={measure.id}>
                  <MeasureTableItem
                    measure={measure}
                    agreement={agreement}
                    loading={loading} />
                </ErrorBoundary>
              ))}
            </tbody>
          </table>
        )}

        {isEmpty && <NoMeasures />}
      </div>
    </ErrorBoundary>
  );
});

interface IStrategicListProps {
  objective: IObjective;
  agreement: IScorecardMetadata;
  loading: boolean;
}
const StrategicList = observer((props: IStrategicListProps) => {
  const { store } = useAppContext();
  const { objective, agreement, loading } = props;

  const getMeasures = (): IMeasure[] => {
    return store.measure.allMe
      .filter((measure) => measure.asJson.objective === objective.id)
      .map((measure) => measure.asJson);
  };

  return (
    <ErrorBoundary>
      <div className="uk-margin">
        <div className="uk-card uk-card-default uk-card-body uk-card-small">
          <MeasureTable
            measures={getMeasures()}
            agreement={agreement}
            loading={loading} />
        </div>
      </div>
    </ErrorBoundary>
  );
});

const IndividualScorecardDraftObjective = observer(() => {
  const { store } = useAppContext();
  const { id: objectiveId } = useParams();
  const objectives = store.objective.allMe;
  const { agreement, loading } = useIndividualScorecard();

  const [_, setTitle] = useTitle(); // set page title
  const [objective, setObjective] = useState<IObjective | null>(null);

  const navigate = useNavigate();

  useBackButton("/c/scorecards/my/");


  const totalWeight = useMemo(
    () => objectives.reduce((acc, curr) => acc + (curr.asJson.weight || 0), 0),
    [objectives]
  );

  const enableEditing = () => {
    const isEditing = agreement.agreementDraft.status === "pending" ||
      agreement.agreementDraft.status === "in-progress"
    return !isEditing;
  };

  const handleNewMeasure = () => {
    store.objective.clearSelected(); // clear selected objective
    store.measure.clearSelected(); // clear selected measure
    showModalFromId(MODAL_NAMES.EXECUTION.MEASURE_MODAL);
  };

  // Get objective
  useEffect(() => {
    const getObjective = () => {
      const objective = objectives.find((o) => o.asJson.id === objectiveId);
      objective ? setObjective(objective.asJson) : navigate("/c/scorecards/my/");
    };
    getObjective();
  }, [objectives, navigate, objectiveId]);

  useEffect(() => {
    const setPageTitle = () => {
      objective ? setTitle(`Individual Scorecard | ${objective.description}`) : setTitle("Individual Scorecard");
    };
    setPageTitle();
  }, [objective, setTitle]);

  if (loading)
    return (
      <ErrorBoundary>
        <LoadingEllipsis />
      </ErrorBoundary>
    );

  return (
    <ErrorBoundary>
      <div className="objective-page uk-section uk-section-small">
        <div className="uk-container uk-container-xlarge">
          <ErrorBoundary>
            <Toolbar
              rightControls={
                <ErrorBoundary>
                  <button
                    className="btn btn-primary"
                    onClick={handleNewMeasure}
                    disabled={enableEditing()}
                  >
                    <span data-uk-icon="icon: plus-circle; ratio:.8"></span> New
                    Measure
                  </button>
                </ErrorBoundary>
              }
            />
          </ErrorBoundary>
          <ErrorBoundary>
            {!store.objective.isEmpty && (
              <WeightError weightError={totalWeight} />
            )}
          </ErrorBoundary>
          <ErrorBoundary>
            {objective && <StrategicList
              objective={objective}
              agreement={agreement}
              loading={loading} />}
          </ErrorBoundary>
        </div>
      </div>

      {/* Modals */}
      <ErrorBoundary>
        <Modal modalId={MODAL_NAMES.EXECUTION.MEASURE_MODAL}>
          <MeasureModal />
        </Modal>
        <Modal
          modalId={MODAL_NAMES.EXECUTION.MEASURE_COMMENTS_MODAL} >
          <MeasureCommentsModal />
        </Modal>
      </ErrorBoundary>
    </ErrorBoundary>
  );
});

export default IndividualScorecardDraftObjective;
