import React, { useEffect, useState } from "react";
import ErrorBoundary from "../../../shared/components/error-boundary/ErrorBoundary";
import { LoadingEllipsis } from "../../../shared/components/loading/Loading";
import useTitle from "../../../shared/hooks/useTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../../shared/functions/Context";
import { getDownloadURL, getMetadata, listAll, ref } from "firebase/storage";
import { storage } from "../../../shared/config/firebase-config";
import { uploadFileToFirebaseStorageNotes } from "./UploadNotes";

const ReleaseNotes = () => {
  useTitle("Release Notes");
  const [loading, setLoading] = useState(false);
  const { store, api, ui } = useAppContext(); // Assuming you have storage from your context
  const [selectedFile, setSelectedFile] = useState(null);
  const currentUser = store.auth.me;
  const name = currentUser?.asJson.displayName || "";

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const uploadDocument = async () => {
    setLoading(true);
    if (selectedFile) {
      await uploadFileToFirebaseStorageNotes(storage, selectedFile, name);
      console.log("File uploaded successfully");
      fetchFilesInFolder();

      setSelectedFile(null);
    }
    setLoading(false);
  };

  const [filesInFolder, setFilesInFolder] = useState<
    { name: string; downloadUrl: string; lastModified: number }[]
  >([]);

  const fetchFilesInFolder = async () => {
    setLoading(true);
    try {
      const listRef = ref(storage, "Notes/");
      const res = await listAll(listRef);

      const fileItems = await Promise.all(
        res.items.map(async (itemRef) => {
          // Get download URL, last modified date, or any other metadata you need
          const downloadUrl = await getDownloadURL(itemRef);
          const metadata = await getMetadata(itemRef);

          // Convert the lastModified date string to a timestamp
          const lastModifiedTimestamp = Date.parse(metadata.updated || "0");

          return {
            name: itemRef.name,
            downloadUrl,
            lastModified: lastModifiedTimestamp,
          };
        })
      );

      // Sort files by latest last modified date
      const sortedFileItems = fileItems.sort(
        (a, b) => b.lastModified - a.lastModified
      );

      setFilesInFolder(sortedFileItems);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
    setLoading(false);
  };

  const groupedFiles = filesInFolder.reduce((groups: any, file) => {
    const date = new Date(file.lastModified);
    const monthYear = `${date.getFullYear()}-${date.getMonth() + 1}`;
    const link = file.downloadUrl;

    if (!groups[monthYear]) {
      groups[monthYear] = [];
    }

    groups[monthYear].push(file);
    return groups;
  }, {});

  useEffect(() => {
    fetchFilesInFolder();
  }, []);

  return (
    <ErrorBoundary>
      <div className="dashboard uk-section uk-section-small">
        <div className="uk-container uk-container-xlarge announcements">
          <div>
            {loading ? (
              <LoadingEllipsis />
            ) : (
              <div>
                {/* <div className="uk-margin" data-uk-margin>
                  <div uk-form-custom="target: true">
                    <input
                      type="file"
                      aria-label="Custom controls"
                      onChange={handleFileChange}
                    />
                    <input
                      className="uk-input uk-form-width-medium"
                      type="text"
                      placeholder="Select file"
                      aria-label="Custom controls"
                      disabled
                    />
                  </div>
                  <button
                    className="uk-button uk-button-default"
                    onClick={uploadDocument}
                  >
                    Upload
                  </button>
                </div> */}

                {Object.keys(groupedFiles).map((groupKey) => (
                  <div key={groupKey}>
                    <h6>
                      {new Date(groupKey).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                      })}
                    </h6>

                    <table className="uk-table uk-table-small uk-table-divider">
                      <thead>
                        <tr>
                          <th>file Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedFiles[groupKey].map((file: any) => (
                          <tr>
                            <td>{file.name}</td>
                            <td>
                              <a
                                href={file.downloadUrl}
                                target="blank"
                                className="download-link"
                              >
                                view
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ReleaseNotes;
