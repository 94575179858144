import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

export const uploadFileToFirebaseStorage = async (
  storage: any,
  file: any,
  name: string
) => {
  try {
    // Generate a random 6-digit number
    const currentDate = Date.now();

    // Add the random number to the file name
    const newFileName = `${name}_${file.name}`;
    // Upload file to Firebase Storage
    const storageRef = ref(storage, `Forms/${newFileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    await uploadTask; // Wait for the upload to complete
    console.log("File uploaded successfully");
  } catch (error) {
    console.log("Error uploading file", error);
  }
};
