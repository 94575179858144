var today: Date = new Date();
var currentHour: number = today.getHours();
var greeting: string = "";

if (currentHour < 12) {
  greeting = "Good Morning";
} else if (currentHour < 18) {
  greeting = "Good Afternoon";
} else {
  greeting = "Good Evening";
}

// SCORECARD DRAFT MAIL NOTIFICATIONS
export const MAIL_SCORECARD_DRAFT_SUBMITTED_MANAGER = (
  name: string | null = ""
) => {
  const SUBJECT = `${name} - Scorecard Submission`;
  const BODY = [
    greeting,
    "",
    `${name} has submitted a draft scorecard for review, subject for approval after performance scorecard discussion between both parties.`,
    `Kindly review the draft and schedule a meeting with ${name} to conclude the scorecard.`,
    "",
    "Visit PMS System: https://namcor.unicomms.app for more.",
    "",
    "Sincerely,",
    name + " - PMS System",
  ];
  return {
    SUBJECT: SUBJECT,
    BODY: BODY.join("<br/>"),
  };
};

export const MAIL_SCORECARD_DRAFT_SUBMITTED_ME = (name: string | null = "") => {
  const MY_SUBJECT = `${name} - Scorecard Submission`;
  const MY_BODY = [
    greeting,
    "",
    `Your draft scorecard has been successfully submitted to your line manager for approval, subject to discussion between both parties.`,
    `Kindly schedule a meeting with your line manager to discuss the draft in person to conclude scorecard.`,
    "",
    "Regards",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// SCORECARD Q1 MAIL NOTIFICATIONS
export const MAIL_SCORECARD_Q1_SUBMITTED_MANAGER = (
  name: string | null = ""
) => {
  const SUBJECT = `${name} - Q1 Submission`;
  const BODY = [
    greeting,
    "",
    `${name} has submitted Q1 progress update for review, subject for approval after discussion between both parties.`,
    `Kindly review the draft and schedule a meeting with ${name} to conclude the Q1 progress update.`,
    "",
    "Visit PMS System: https://namcor.unicomms.app for more.",
    "",
    "Sincerely,",
    name + " - PMS System",
  ];

  return {
    SUBJECT: SUBJECT,
    BODY: BODY.join("<br/>"),
  };
};
export const MAIL_SCORECARD_Q1_SUBMITTED_ME = (name: string | null = "") => {
  const MY_SUBJECT = `${name} - Q1 Submission`;
  const MY_BODY = [
    greeting,
    "",
    `Your Q1 progress update has been successfully submitted to your line manager for approval, subject to discussion between both parties.`,
    `Kindly schedule a meeting with your line manager to discuss the Q1 in person to conclude scorecard.`,
    "",
    "Visit PMS System: https://namcor.unicomms.app for more.",
    "",
    "Regards",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// SCORECARD Q2 MAIL NOTIFICATIONS
export const MAIL_SCORECARD_Q2_SUBMITTED_MANAGER = (
  name: string | null = ""
) => {
  const SUBJECT = `${name} - Mid-Term Submission`;
  const BODY = [
    greeting,
    "",
    `${name} has submitted a draft mid-term progress update for review, subject for approval after discussion between both parties.`,
    `Kindly review the draft and schedule a meeting with ${name} to conclude the mid-term progress update.`,
    "",
    "Visit PMS System: https://namcor.unicomms.app for more.",
    "",
    "Sincerely,",
    name + " - PMS System",
  ];

  return {
    SUBJECT: SUBJECT,
    BODY: BODY.join("<br/>"),
  };
};
export const MAIL_SCORECARD_Q2_SUBMITTED_ME = (name: string | null = "") => {
  const MY_SUBJECT = `${name} - Mid-Term Submission`;
  const MY_BODY = [
    greeting,
    "",
    `Your draft mid-term progress update has been successfully submitted to your line manager for approval, subject to discussion between both parties.`,
    `Kindly schedule a meeting with your line manager to discuss the draft in person to conclude scorecard.`,
    "",
    "Visit PMS System: https://namcor.unicomms.app for more.",
    "",
    "Regards",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// SCORECARD Q3 MAIL NOTIFICATIONS
export const MAIL_SCORECARD_Q3_SUBMITTED_MANAGER = (
  name: string | null = ""
) => {
  const SUBJECT = `${name} - Q3 Submission`;
  const BODY = [
    greeting,
    "",
    `${name} has submitted Q3 progress update for review, subject for approval after discussion between both parties.`,
    `Kindly review the draft and schedule a meeting with ${name} to conclude the Q3 progress update.`,
    "",
    "Visit PMS System: https://namcor.unicomms.app for more.",
    "",
    "Sincerely,",
    name + " - PMS System",
  ];

  return {
    SUBJECT: SUBJECT,
    BODY: BODY.join("<br/>"),
  };
};
export const MAIL_SCORECARD_Q3_SUBMITTED_ME = (name: string | null = "") => {
  const MY_SUBJECT = `${name} - Q3 Submission`;
  const MY_BODY = [
    greeting,
    "",
    `Your Q3 progress update has been successfully submitted to your line manager for approval, subject to discussion between both parties.`,
    `Kindly schedule a meeting with your line manager to discuss the Q3 in person to conclude scorecard.`,
    "",
    "Visit PMS System: https://namcor.unicomms.app for more.",
    "",
    "Regards",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// SCORECARD Q4 MAIL NOTIFICATIONS
export const MAIL_SCORECARD_Q4_SUBMITTED_MANAGER = (
  name: string | null = ""
) => {
  const SUBJECT = `${name} - Appraisal Submission`;
  const BODY = [
    greeting,
    "",
    `${name}  has submitted their self assessment for your review, subject for approval after performance appraisal discussion between both parties.`,
    `Kindly input manager rating and schedule a meeting with ${name} to conclude the appraisal. `,
    "",
    "Visit PMS System: https://namcor.unicomms.app for more.",
    "",
    "Sincerely,",
    name + " - PMS System",
  ];

  return {
    SUBJECT: SUBJECT,
    BODY: BODY.join("<br/>"),
  };
};
export const MAIL_SCORECARD_Q4_SUBMITTED_ME = (name: string | null = "") => {
  const MY_SUBJECT = `${name} - Appraisal Submission`;
  const MY_BODY = [
    greeting,
    "",
    `Your self assesment has been successfully submitted to your line manager for approval, subject to discussion between both parties.`,
    `Kindly schedule a meeting with your line manager to conclude the appraisal. Ensure that you have loaded your portfolio of evidence.`,
    "",
    "Visit PMS System: https://namcor.unicomms.app for more.",
    "",
    "Regards",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

//draft approval
export const MAIL_SCORECARD_APPROVED_ME = (
  employeeName: string | null | undefined,
  supervisorName: string | null | undefined
) => {
  const MY_SUBJECT = `${employeeName}, Peformance Contract Approval`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your peformance contract has been approved By ${supervisorName}.`,
    "",
    "Regards,",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

//upload file notification

export const MAIL_UPLOAD_NOTIFICATION = (
  employeeName: string | null | undefined
) => {
  const MY_SUBJECT = `Ticket Issue Logged`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `A Performance Management System issue has been reported by (User Name) from (client, e.g., Namcor). Kindly address this matter.`,
    "Ticket Number: [Insert Ticket Number]",
    "Regards,",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// draft rejection
export const MAIL_SCORECARD_REJECTED_ME = (
  employeeName: string | null | undefined,
  supervisorName: string | null | undefined
) => {
  const MY_SUBJECT = `${employeeName}, Peformance Contract Reverted`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your peformance contract has been reverted By ${supervisorName}.`,
    "For more information, log into  https://namcor.unicomms.app and read the provided feedback.",
    "Regards,",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

//Q1 approval

// midterm approval
export const MAIL_MIDTERM_APPROVED_ME = (
  employeeName: string | null | undefined,
  supervisorName: string | null | undefined
) => {
  const MY_SUBJECT = `${employeeName}, Mid-Term Approval`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your midterm scorecard has been successfully approved By ${supervisorName}.`,
    "",
    "Regards,",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};
//midterm rejection
export const MAIL_MIDTERM_REJECTED_ME = (
  employeeName: string | null | undefined,
  supervisorName: string | null | undefined
) => {
  const MY_SUBJECT = `${employeeName}, Mid-Term Rejection`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your midterm scorecard has been reverted By ${supervisorName}.`,
    "For more information, log into  https://namcor.unicomms.app and read the provided feedback.",
    "Regards,",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// assessment approval
export const MAIL_ASSESSMENT_APPROVED_ME = (
  employeeName: string | null | undefined,
  supervisorName: string | null | undefined
) => {
  const MY_SUBJECT = `${employeeName}, Assessment Approval`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your assessment scorecard has been successfully approved By ${supervisorName}.`,
    "",
    "Regards,",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// assessment rejection
export const MAIL_ASSESSMENT_REJECTED_ME = (
  employeeName: string | null | undefined,
  supervisorName: string | null | undefined
) => {
  const MY_SUBJECT = `${employeeName}, Assessment Reverted`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your assessment scorecard has been reverted By ${supervisorName}.`,
    "For more information, log into  https://namcor.unicomms.app and read the provided feedback.",
    "Regards,",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// ***********************************************************************************************//
export const MAIL_SCORECARD_DRAFT_APPROVED = (
  supervisorName: string | null
) => {
  const MY_SUBJECT = `Draft Peformance Contract Approval`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your draft peformance contract has been approved By ${supervisorName}.`,
    "",
    "Regards,",
    "PMS System",
  ];
  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

export const MAIL_SCORECARD_DRAFT_REJECTED = (
  supervisorName: string | null
) => {
  const MY_SUBJECT = `Draft Peformance Contract Reverted`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your peformance contract has been reverted By ${supervisorName}.`,
    "For more information, log into  https://namcor.unicomms.app and read the provided feedback.",
    "Regards,",
    "PMS System",
  ];
  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// END DRAFT

// START Q1
export const MAIL_SCORECARD_Q1_APPROVED = (supervisorName: string | null) => {
  const MY_SUBJECT = `Q1 Peformance Contract Approval`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your draft peformance contract has been approved By ${supervisorName}.`,
    "",
    "Regards,",
    "PMS System",
  ];
  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

export const MAIL_SCORECARD_Q1_REJECTED = (supervisorName: string | null) => {
  const MY_SUBJECT = `Q1 Peformance Contract Reverted`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your Q1 peformance contract has been reverted By ${supervisorName}.`,
    "For more information, log into  https://namcor.unicomms.app and read the provided feedback.",
    "Regards,",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

//Q2 approval
export const MAIL_SCORECARD_Q2_APPROVED = (supervisorName: string | null) => {
  const MY_SUBJECT = `Q2 Peformance Contract Approval`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your Q2 peformance contract has been approved By ${supervisorName}.`,
    "",
    "Regards,",
    "PMS System",
  ];
  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// Q2 rejection
export const MAIL_SCORECARD_Q2_REJECTED = (supervisorName: string | null) => {
  const MY_SUBJECT = `Q2 Peformance Contract Reverted`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your Q2 peformance contract has been reverted By ${supervisorName}.`,
    "For more information, log into  https://namcor.unicomms.app and read the provided feedback.",
    "Regards,",
    "PMS System",
  ];
  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

//Q3 approval
export const MAIL_SCORECARD_Q3_APPROVED = (supervisorName: string | null) => {
  const MY_SUBJECT = `Q3 Peformance Contract Approval`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your Q3 peformance contract has been approved By ${supervisorName}.`,
    "",
    "Regards,",
    "PMS System",
  ];
  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// Q3 rejection
export const MAIL_SCORECARD_Q3_REJECTED = (supervisorName: string | null) => {
  const MY_SUBJECT = `Q3 Peformance Contract Reverted`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your Q3 peformance contract has been reverted By ${supervisorName}.`,
    "For more information, log into  https://namcor.unicomms.app and read the provided feedback.",
    "Regards,",
    "PMS System",
  ];
  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

//Q4 approval
export const MAIL_SCORECARD_Q4_APPROVED = (supervisorName: string | null) => {
  const MY_SUBJECT = `Q4 Peformance Contract Approval`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your Q4 peformance contract has been approved By ${supervisorName}.`,
    "",
    "Regards,",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};

// Q4 rejection
export const MAIL_SCORECARD_Q4_REJECTED = (supervisorName: string | null) => {
  const MY_SUBJECT = `Q4 Peformance Contract Reverted`;
  const MY_BODY = [
    `${greeting},`,
    "",
    `Your Q4 peformance contract has been reverted By ${supervisorName}.`,
    "For more information, log into  https://namcor.unicomms.app and read the provided feedback.",
    "Regards,",
    "PMS System",
  ];

  return {
    MY_SUBJECT: MY_SUBJECT,
    MY_BODY: MY_BODY.join("<br/>"),
  };
};
// *******************************************************************************************//

export const MAIL_EMAIL = "no-reply@namcorpms.com";
