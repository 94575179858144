import { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { USER_ROLES } from "./shared/functions/CONSTANTS";
import { AppContext, useAppContext } from "./shared/functions/Context";

import AppApi from "./shared/apis/AppApi";
import Loading from "./shared/components/loading/Loading";
import SnackbarManager from "./shared/components/snackbar/SnackbarManager";
import AppStore from "./shared/stores/AppStore";
import UiStore from "./shared/stores/UiStore";
import Dashboard from "./logged-in/dashboard/Dashboard";
import IndividualScorecard from "./logged-in/execution-scorecard/IndividualScorecard";
import People from "./logged-in/execution-team/People";
import AdminSettings from "./logged-in/admin-settings/AdminSettings";
import Reports from "./logged-in/reports/Reports";
import EmployeeScorecard from "./logged-in/execution-supervision/EmployeeScorecard";
import PeopleView from "./logged-in/execution-team-view/PeopleView";
import EmployeeScorecardView from "./logged-in/execution-supervision-view/EmployeeScorecardView";
import PrivateRoute from "./shared/functions/PrivateRoute";
import LoggedOut from "./logged-out/LoggedOut";
import CompanyScorecards from "./logged-in/company-scorecards/CompanyScorecards";
import CompanyScorecard from "./logged-in/company-scorecard/CompanyScorecard";
import DepartmentScorecards from "./logged-in/department-scorecards/DepartmentScorecards";
import DepartmentScorecard from "./logged-in/department-scorecard/DepartmentScorecard";
import CompanyScorecardObjective from "./logged-in/company-scorecard-objective/CompanyScorecardObjective";
import DepartmentScorecardObjective from "./logged-in/department-scorecard-objective/DepartmentScorecardObjective";
import PerformanceReviews from "./logged-in/performance-reviews/PerformanceReviews";
import Drive from "./logged-in/drive/Drive";
import IndividualScorecardDraftObjective from "./logged-in/execution-scorecard-objective-draft/IndividualScorecardDraftObjective";
import StrategyThemes from "./logged-in/strategy-themes/StrategyThemes";
import useNetwork from "./shared/hooks/useNetwork";
import DepartmentScorecardReviews from "./logged-in/strategy-department-scorecard-reviews/DepartmentScorecardReviews";
import DepartmentScorecardReviewView from "./logged-in/strategy-department-scorecard-reviews-view/DepartmentScorecardReviewView";
import CompanyScorecardReviews from "./logged-in/company-scorecard-reviews/CompanyScorecardReviews";
import CompanyScorecardReviewView from "./logged-in/company-scorecard-reviews-view/CompanyScorecardReviewView";
import StrategyMapPage from "./logged-in/execution-scorecard-strategy-map/StrategyMapPage";
import "./App.scss";
import { Announcements } from "./logged-in/support/announcements/Announcements";
import { Forms } from "./logged-in/support/forms/Forms";
import { Links } from "./logged-in/support/links/Links";
import UserManual from "./logged-in/support/user-maual/UserMaual";
import ReleaseNotes from "./logged-in/support/release-notes/ReleaseNotes";

// unHenRNd4qReXbMr6vjcke9jH1p1 david
// okUEpLoRUYe7hcPTjySuZ3C3XEK2 abisai
// HGy4uc0MOeRjvL4L6ggLgyhaxmo1 user4

// Lazy load components
const LoggedIn = lazy(() => import("./logged-in/LoggedIn"));

const PrivateLoggedIn = () => (
  <PrivateRoute>
    <Suspense fallback={<Loading fullHeight={true} />}>
      <LoggedIn />
    </Suspense>
  </PrivateRoute>
);
const DEV_MODE_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/themes" element={<StrategyThemes />} />
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/company-review"
            element={<CompanyScorecardReviews />}
          />
          <Route
            path="strategy/company-review/:fyid"
            element={<CompanyScorecardReviewView />}
          />

          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />

          <Route
            path="strategy/department-review"
            element={<DepartmentScorecardReviews />}
          />
          <Route
            path="strategy/department-review/:fyid/:departmentId"
            element={<DepartmentScorecardReviewView />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* REPORTS */}
          <Route path="reports/kpis" element={<Reports />} />

          {/* SUPPORT */}
          <Route path="support/announcement" element={<Announcements />} />
          <Route path="support/forms" element={<Forms />} />
          <Route path="support/links" element={<Links />} />
          <Route path="support/links" element={<Links />} />

          <Route path="support/manual" element={<UserManual />} />
          <Route path="support/notes" element={<ReleaseNotes />} />

          {/* ADMIN */}
          <Route path="admin/settings" element={<AdminSettings />} />
          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LoggedOut />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const DIRECTOR_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/themes" element={<StrategyThemes />} />
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/company-review"
            element={<CompanyScorecardReviews />}
          />
          {/* SUPPORT */}
          <Route path="support/announcement" element={<Announcements />} />
          <Route path="support/forms" element={<Forms />} />
          <Route path="support/links" element={<Links />} />
          <Route path="support/links" element={<Links />} />
          <Route path="support/manual" element={<UserManual />} />
          <Route path="support/notes" element={<ReleaseNotes />} />

          <Route
            path="strategy/company-review/:fyid"
            element={<CompanyScorecardReviewView />}
          />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LoggedOut />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const MD_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* STRATEGY */}
          <Route path="strategy/themes" element={<StrategyThemes />} />
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />
          {/* SUPPORT */}
          <Route path="support/announcement" element={<Announcements />} />
          <Route path="support/forms" element={<Forms />} />
          <Route path="support/links" element={<Links />} />
          <Route path="support/manual" element={<UserManual />} />
          <Route path="support/notes" element={<ReleaseNotes />} />

          <Route
            path="strategy/department-review"
            element={<DepartmentScorecardReviews />}
          />
          <Route
            path="strategy/department-review/:fyid/:departmentId"
            element={<DepartmentScorecardReviewView />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          {/* <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          /> */}
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* REPORTS */}
          <Route path="reports/kpis" element={<Reports />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LoggedOut />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const SUPER_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* SUPPORT */}
          <Route path="support/announcement" element={<Announcements />} />
          <Route path="support/forms" element={<Forms />} />
          <Route path="support/links" element={<Links />} />
          <Route path="support/manual" element={<UserManual />} />
          <Route path="support/notes" element={<ReleaseNotes />} />

          {/* STRATEGY */}
          <Route path="strategy/themes" element={<StrategyThemes />} />
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />
          <Route
            path="strategy/department-review"
            element={<DepartmentScorecardReviews />}
          />
          <Route
            path="strategy/department-review/:fyid/:departmentId"
            element={<DepartmentScorecardReviewView />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* REPORTS */}
          <Route path="reports/kpis" element={<Reports />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LoggedOut />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const EXECUTIVE_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* SUPPORT */}
          <Route path="support/announcement" element={<Announcements />} />
          <Route path="support/forms" element={<Forms />} />
          <Route path="support/links" element={<Links />} />
          <Route path="support/manual" element={<UserManual />} />
          <Route path="support/notes" element={<ReleaseNotes />} />

          {/* STRATEGY */}
          <Route path="strategy/themes" element={<StrategyThemes />} />
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />

          {/* SCORECARDS */}
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* REPORTS */}
          <Route path="reports/kpis" element={<Reports />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LoggedOut />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const ADMIN_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* SUPPORT */}
          <Route path="support/announcement" element={<Announcements />} />
          <Route path="support/forms" element={<Forms />} />
          <Route path="support/links" element={<Links />} />
          <Route path="support/manual" element={<UserManual />} />
          <Route path="support/notes" element={<ReleaseNotes />} />

          {/* STRATEGY */}
          <Route path="strategy/themes" element={<StrategyThemes />} />
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />

          {/* SCORECARDS */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          {/* ADMIN */}
          <Route path="admin/settings" element={<AdminSettings />} />
          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LoggedOut />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const MANAGER_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* SUPPORT */}
          <Route path="support/announcement" element={<Announcements />} />
          <Route path="support/forms" element={<Forms />} />
          <Route path="support/links" element={<Links />} />
                    <Route path="support/manual" element={<UserManual />} />
          <Route path="support/notes" element={<ReleaseNotes />} />

          {/* STRATEGY */}
          <Route path="strategy/themes" element={<StrategyThemes />} />
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />

          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LoggedOut />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const SUPERVISOR_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* SUPPORT */}
          <Route path="support/announcement" element={<Announcements />} />
          <Route path="support/forms" element={<Forms />} />
          <Route path="support/links" element={<Links />} />
          <Route path="support/manual" element={<UserManual />} />
          <Route path="support/notes" element={<ReleaseNotes />} />
          

          {/* STRATEGY */}
          <Route path="strategy/themes" element={<StrategyThemes />} />
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />

          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />
          <Route
            path="scorecards/supervision"
            element={<EmployeeScorecard />}
          />
          <Route
            path="scorecards/supervision/:uid"
            element={<EmployeeScorecardView />}
          />
          <Route path="scorecards/people" element={<People />} />
          <Route path="scorecards/people/:uid" element={<PeopleView />} />
          <Route path="scorecards/reviews" element={<PerformanceReviews />} />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LoggedOut />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const EMPLOYEE_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* SUPPORT */}
          <Route path="support/announcement" element={<Announcements />} />
          <Route path="support/forms" element={<Forms />} />
          <Route path="support/links" element={<Links />} />
          <Route path="support/manual" element={<UserManual />} />
          <Route path="support/notes" element={<ReleaseNotes />} />

          {/* STRATEGY */}
          <Route path="strategy/themes" element={<StrategyThemes />} />
          <Route path="strategy/company" element={<CompanyScorecards />} />
          <Route path="strategy/company/:fyid" element={<CompanyScorecard />} />
          <Route
            path="strategy/company/:fyid/:objectiveId"
            element={<CompanyScorecardObjective />}
          />
          <Route
            path="strategy/department"
            element={<DepartmentScorecards />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId"
            element={<DepartmentScorecard />}
          />
          <Route
            path="strategy/department/:fyid/:departmentId/:objectiveId"
            element={<DepartmentScorecardObjective />}
          />

          {/* SCORECARDS */}
          {/* Executive & MD don't have personal/individual scorecard */}
          <Route path="scorecards/my" element={<IndividualScorecard />} />
          <Route
            path="scorecards/my/:id"
            element={<IndividualScorecardDraftObjective />}
          />

          {/* PORTFOLIO OF EVIDENCE */}
          <Route path="drive" element={<Drive />} />
          <Route path="drive/:id" element={<Drive />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LoggedOut />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
const GUEST_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          {/* OVERVIEW */}
          <Route path="home/dashboard" element={<Dashboard />} />
          <Route path="home/strategy-map" element={<StrategyMapPage />} />

          {/* SUPPORT */}
          <Route path="support/announcement" element={<Announcements />} />
          <Route path="support/forms" element={<Forms />} />
          <Route path="support/links" element={<Links />} />
          <Route path="support/manual" element={<UserManual />} />
          <Route path="support/notes" element={<ReleaseNotes />} />

          <Route path="*" element={<Navigate to="home/dashboard" />} />
        </Route>

        <Route path="/" element={<LoggedOut />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

const MainRoutes = observer(() => {
  const { store } = useAppContext();
  const role = store.auth.role;

  useNetwork();

  const DEV_MODE =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  if (DEV_MODE) return <DEV_MODE_ROUTES />;

  switch (role) {
    case USER_ROLES.DIRECTOR_USER:
      return <DIRECTOR_USER_ROUTES />;

    case USER_ROLES.MD_USER:
      return <MD_USER_ROUTES />;

    case USER_ROLES.SUPER_USER:
      return <SUPER_USER_ROUTES />;

    case USER_ROLES.EXECUTIVE_USER:
      return <EXECUTIVE_USER_ROUTES />;

    case USER_ROLES.ADMIN_USER:
      return <ADMIN_USER_ROUTES />;

    case USER_ROLES.MANAGER_USER:
      return <MANAGER_USER_ROUTES />;

    case USER_ROLES.SUPERVISOR_USER:
      return <SUPERVISOR_USER_ROUTES />;

    case USER_ROLES.EMPLOYEE_USER:
      return <EMPLOYEE_USER_ROUTES />;

    default:
      return <GUEST_USER_ROUTES />;
  }
});

const App = () => {
  const store = new AppStore();
  const api = new AppApi(store);
  const ui = new UiStore();

  return (
    <div className="app">
      <AppContext.Provider value={{ store, api, ui }}>
        <MainRoutes />
        <SnackbarManager />
      </AppContext.Provider>
    </div>
  );
};

export default App;

// HgJEiB0Q6NXtoKpiW6WwRJxAkFO2

// XoGH6c2rJBbDvGRi7uEqrOOYZdB3 shimabshike immanuel
// X4NqJTHCizMgmcGRmZ01h30Ulcy2 tostao haitembu
// VXotOSaTEThryu1OoGYtUlEDvA72 clementine matheus
// vjTpq7Df71MtVtwSYvUsrvIUYij1 onni nembia
// okUEpLoRUYe7hcPTjySuZ3C3XEK2 abisai ndeunyema
// fglspuNuuuMyVqjH7THvJ51xs5r1 Michelo diergaardt
// vBk6r25htfcR0EkcXnAQrqixIp83 frank fielding

// eUDoBcrfClhiiQsY840y otjidepo department
// vsCLcGRZ476Ohyw4VW6q nosfof department
