import { observer } from "mobx-react-lite";
import ErrorBoundary from "../../../shared/components/error-boundary/ErrorBoundary";
import useTitle from "../../../shared/hooks/useTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

export const Links = observer(() => {
  useTitle("Ticket Submission Link");
  return (
    <ErrorBoundary>
      <div className="dashboard uk-section uk-section-small ">
        <div className="uk-container uk-container-xlarge announcements">
          <div style={{ width: "20%" }}>
            <a
              href="https://airtable.com/app6QJEHUuKJPZoij/shr0ytPlH34JiQpcl"
              target="_blank"
              rel="noopener noreferrer"
              className="center-content"
            >
              <FontAwesomeIcon
                icon={faLink}
                className="icon uk-margin-small-right"
                style={{ fontSize: "3rem", color: "grey" }}
              />
            </a>
            <p className="center-content">Ticket Submission Link</p>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
});
