import { observer } from "mobx-react-lite";
import { useAppContext } from "../../functions/Context";

const VisionMission = observer(() => {
  const { store } = useAppContext();
  return (
    <div className="header">
      {store.visionmission.all.map((vm) => (
        <>
          <h6 className="vision">Vision: {vm.asJson.vision}</h6>
          <h6 className="mission">Mission: {vm.asJson.mission}</h6>
        </>
      ))}
    </div>
  );
});
export default VisionMission;
