import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import { dataFormat } from "../../../shared/functions/Directives";
import { IMeasureDepartment } from "../../../shared/models/MeasureDepartment";
import { IObjective } from "../../../shared/models/Objective";
import Rating from "../../shared/components/rating/Rating";
import { totalQ4DepartmentObjectiveRating } from "../../shared/functions/Scorecard";
import StatusDirection from "../../shared/components/rating/StatusDirection";

interface IProps {
  objective: IObjective;
}
const StrategicMapOverview = observer((props: IProps) => {
  const { store } = useAppContext();
  const { objective } = props;

  const [measures, setMeasures] = useState<IMeasureDepartment[]>([]);

  const calculateRating = () => {
    const rating = totalQ4DepartmentObjectiveRating(measures);
    return rating || 1;
  };

  // Get measures that belong to objective
  const getMeasures = useCallback((): IMeasureDepartment[] => {
    return store.departmentMeasure.all.filter((measure) => measure.asJson.objective === objective.id)
      .map((measure) => measure.asJson);
  }, [objective.id, store.departmentMeasure.all]);

  useEffect(() => {
    setMeasures(getMeasures());
  }, [getMeasures]);

  return (
    <div className="strategic-map-overview">
      <div className="grid">
        <div className="kpis uk-card uk-card-default uk-card-small uk-card-body uk-width-expand">
          <h6 className="sub-heading">KPIs</h6>
          <table className="kpis-table uk-table uk-table-justify uk-table-divider uk-table-hover">
            <thead>
              <tr>
                <th>KPIs</th>
                <th>Target</th>
                <th>Actual</th>
              </tr>
            </thead>
            <tbody>
              {measures.map((measure) => (
                <tr key={measure.id}>
                  <td className="kpi-name">{measure.description}</td>
                  <td className="kpi-target">
                    {dataFormat(measure.dataType, measure.annualTarget, measure.dataSymbol)}
                  </td>
                  <td className="kpi-actual">
                    <StatusDirection rating={measure.q4Rating || measure.q4AutoRating} />
                    {dataFormat(measure.dataType, measure.annualActual, measure.dataSymbol)}
                  </td>
                </tr>
              ))}

              {measures.length === 0 && (
                <tr>
                  <td colSpan={3}>
                    <div className="uk-text-center">
                      <h5>No KPIs found for this objective</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="score uk-card uk-card-default uk-card-body uk-card-small">
          <h6 className="sub-heading">Rating</h6>
          <div className="rating-container uk-margin">
            <Rating rate={calculateRating()} simple={false} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default StrategicMapOverview;
