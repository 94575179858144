import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorBoundary from "../../../shared/components/error-boundary/ErrorBoundary";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";

export const DownloadDocs = () => {
  const pdfUrl = process.env.PUBLIC_URL + "/Ticket.docx";
  return (
    <ErrorBoundary>
      <div className="dashboard uk-section uk-section-small ">
        <div className="uk-container uk-container-xlarge announcements">
          <div style={{ width: "20%" }}>
            <a
              href={pdfUrl}
              download  // Add the 'download' attribute here
              className="center-content"
            >
              <FontAwesomeIcon
                icon={faFileWord}
                className="icon uk-margin-small-right"
                style={{ fontSize: "4rem", color: "grey" }}
              />
            </a>
            <p className="center-content">Change Request Form</p>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
